import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const PolyLineDetail = ({ item }) => {
  return (
    <Grid container direction="column">
      <Grid item>
        <Box display="inline-flex" justifyContent="space-between" width="100%">
          <Typography className="lblStyle">Name</Typography>

          <Typography className="lblStyle" sx={{ color: item?.color }}>
            {item?.name}
          </Typography>
        </Box>
      </Grid>
      <Grid item>
        <Box display="inline-flex" justifyContent="space-between" width="100%">
          <Typography className="lblStyle">Label</Typography>
          <Typography className="lblStyle">{item?.label}</Typography>
        </Box>
      </Grid>
      {/* {item.properties.diff_in_elevation && ( */}
      <Grid item>
        <Box display="inline-flex" justifyContent="space-between" width="100%">
          <Typography className="lblStyle">Elevation Difference</Typography>
          <Typography className="lblStyle">
            {typeof item?.properties?.diff_in_elevation === "number"
              ? item?.properties?.diff_in_elevation?.toFixed(2) + " m"
              : "-"}
          </Typography>
        </Box>
      </Grid>
      {/* )} */}
      {/* {item.properties.length && ( */}
      <Grid item>
        <Box display="inline-flex" justifyContent="space-between" width="100%">
          <Typography className="lblStyle">Length</Typography>
          <Typography className="lblStyle">
            { item?.properties?.length
              ? item?.properties?.length.toFixed(2) + " " + item?.properties?.unit
              : "-"}
          </Typography>
        </Box>
      </Grid>
      {/* )} */}
      {/* {item.properties.slope && ( */}
      <Grid item>
        <Box display="inline-flex" justifyContent="space-between" width="100%">
          <Typography className="lblStyle">Slope</Typography>
          <Typography className="lblStyle">
            {typeof item?.properties?.slope === "number"
              ? (item?.properties?.slope).toFixed(2) + "%"
              : "-"}
          </Typography>
        </Box>
      </Grid>
      {/* )} */}

      <Grid item>
        <Box display="inline-flex" justifyContent="space-between" width="100%">
          <Typography className="lblStyle">Slope Ratio</Typography>
          <Typography className="lblStyle">
            {item?.properties?.["slope-ratio"] || '-'}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default PolyLineDetail;
