import AccountMain from "../../components/Account/AccountMain";

const AccountPage = () => {
  return(
    <>
    <AccountMain />
    </>
  )
}

export default AccountPage