import {createSlice} from "@reduxjs/toolkit";

const measurementCreateSlice = createSlice({
  name: "measurementCreate",
  initialState: {
    type: "",
    geojson: {},
    loading: false
  },
  reducers: {
    createMeasurementAction(state) {
      return {
        ...state,
        loading: true
      }
    },
    setMeasurementGeoJsonAndType(state, action) {
      return {
        ...state,
        type: action.payload.type,
        geojson: action.payload.geojson
      }
    },
    resetCreateMeasureAction() {
      return {
        type: "",
        geojson: {},
        loading: false
      }
    }
  }
})

export const {setMeasurementGeoJsonAndType, createMeasurementAction, resetCreateMeasureAction} = measurementCreateSlice.actions

export default measurementCreateSlice.reducer