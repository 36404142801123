import { useState, useEffect } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { useDispatch, useSelector } from "react-redux";
import {
  handleMeasurementListDataAction,
  setMeasurementFilterTypeAction,
} from "../../../redux/slices/measurement/list";
import PrivateRequestBase from "redux/sagas/requests/rootRequest/PrivateRequestBase";

const FilterMeasurementMenu = ({ handleToggleMenuClose, anchorEl }) => {
  const openMenu = Boolean(anchorEl);
  const dispatch = useDispatch();
  const selectedFlightUuid = useSelector((state) => state.flightSelected.uuid);

  const measurementFilters = useSelector(
    (state) => state.measurementList.filters.type
  );

  const [selectedCheckboxes, setSelectedCheckboxes] = useState({
    Point: true,
    LineString: true,
    Polygon: true,
  });

  useEffect(() => {
    if (Array.isArray(measurementFilters)) {
      const initialSelectedCheckboxes = measurementFilters.reduce((obj, type) => {
        obj[type] = true;
        return obj;
      }, {});
      setSelectedCheckboxes(initialSelectedCheckboxes);
    }
  }, [measurementFilters]);

  const handleClick = async (type) => {
    const newSelectedCheckboxes = { ...selectedCheckboxes, [type]: !selectedCheckboxes[type] };

    const allUnchecked = Object.values(newSelectedCheckboxes).every(value => value === false);

    if (allUnchecked) {
      setSelectedCheckboxes({
        Point: true,
        LineString: true,
        Polygon: true,
      });
      updateMeasurements(["Point", "LineString", "Polygon"]);
    } else {
      setSelectedCheckboxes(newSelectedCheckboxes);
      const filtersArray = Object.keys(newSelectedCheckboxes).filter(key => newSelectedCheckboxes[key]);
      updateMeasurements(filtersArray);
    }
  };

  const updateMeasurements = async (filtersArray) => {
    dispatch(setMeasurementFilterTypeAction({ type: filtersArray }));
    const typesArray = filtersArray.map(filter => {
      switch (filter) {
        case "Point":
          return 0;
        case "LineString":
          return 1;
        case "Polygon":
          return 2;
        default:
          return 0; 
      }
    });

    const typesParam = typesArray.join(',');
    const apiUrl = `${process.env.REACT_APP_BACKEND_URL}/filters/measurements/?flight_uuid=${selectedFlightUuid}&types=${typesParam}`;
    const response = await PrivateRequestBase("get", apiUrl);
    dispatch(handleMeasurementListDataAction({ data: response?.data?.data }));
  };

  return (
    <Menu
      id="measurement-filter-dialog"
      aria-labelledby="measurement-filter-dialog"
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={openMenu}
      anchorEl={anchorEl}
      onClose={handleToggleMenuClose}
      className="menuToggleCls"
    >
      <List disablePadding>
        {["Point", "LineString", "Polygon"].map((type) => (
          <MenuItem key={type}>
            <ListItem disablePadding>
              <label>
                <input 
                  type="checkbox" 
                  checked={selectedCheckboxes[type] || false} 
                  onChange={() => handleClick(type)}
                />
                {type === "LineString" ? "Polyline" : type}
              </label>
            </ListItem>
          </MenuItem>
        ))}
      </List>
    </Menu>
  );
};

export default FilterMeasurementMenu;
