import Grid from "@mui/material/Grid";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import LineworkItem from "components/Linework/LineworkItem";
import { Box, Divider, Typography } from "@mui/material";
import { NavLink } from "react-router-dom";
import Button from "../Common/Button";
import { getLineworkListAction } from "redux/slices/linework/list";
import styled from 'styled-components';

const StyledGrid = styled(Grid)`
  margin: 10px !important;
`;

const LineworkList = () => {
  const linework = useSelector((state) => state?.lineworkList?.data);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getLineworkListAction());

  }, []);

  return (
    <>
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        pt={2}
        pb={2}
      >
        <Typography variant="h4">Lineworks</Typography>
        <Box pt={2}>
          <Button
            label="Add New Linework"
            size="small"
            sx={{
              height: 37,
            }}
            component={NavLink}
            to="/linework/add"
          />
        </Box>
      </Box>
      <Divider />

      {linework ? linework?.map((lw) => {
        return (
          <StyledGrid item xs key={lw.id}>
            <LineworkItem LineworkItem={lw} />
          </StyledGrid>
        );
      })
        : (
          <Typography align="center" mt={4}>
            No linework Found
          </Typography>
        )}
      {/*<MaterialItem/>*/}
    </>
  );
};

export default LineworkList;
