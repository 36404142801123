import { useState } from "react";
import Button from "../Common/Button";
import { setProjectListMapViewAction } from "../../redux/slices/project/list";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { logoutAction } from "../../redux/slices/basic/LogoutSlice";
import { Avatar ,
  Menu,
  MenuItem,
  Typography,
  Box,
} from "@mui/material";

import { styled } from "@mui/material/styles";

import Loader from "../Common/Loader";

const MainWraper = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-end",
  alignItems: "center",
}));
const AccountSection = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  padding: "10px 10px",
  borderBottom: "1px solid #ccc",
}));

const ProjectAppBarContent = () => {
  // const [isLoggedIn, setIsLoggedIn] = useState(false);

  const mapView = useSelector((state) => state.projectList.mapView);
  const name = localStorage.getItem("name");
  const imageUrl = null; // localStorage.getItem("imageUrl"); 
  const initials = name
  ? name
      .split(" ") 
      .map((part) => part.charAt(0)) 
      .slice(0, 2) 
      .join("") 
  : "";
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // useEffect(() => {
  //   const userEmail = localStorage.getItem("userEmail");
  //   const userAuthenticated = localStorage.getItem("isAuthenticated");
  //   if (userEmail && userAuthenticated) {
  //     setIsLoggedIn(true);
  //   } else {
  //     setIsLoggedIn(false);
  //   }
  // }, []);

  const handleLogoutAndNavigation = () => {
    setLoading(true);

    setTimeout(() => {
      dispatch(logoutAction());
      localStorage.removeItem("token");
      localStorage.removeItem("isAuthenticated");
      navigate("/");
    }, 3000);
  };

  const removeLayerAndSource = (layerId, sourceId) => {
    if (window.map.getLayer(layerId)) {
      window.map.removeLayer(layerId);
    }
    if (window.map.getSource(sourceId)) {
      window.map.removeSource(sourceId);
    }
  };

  const handleMapViewClick = () => {
    removeLayerAndSource('wms-layer-1', 'wms-source');
    removeLayerAndSource('wms-layer-2', 'wms-source-2');
    dispatch(setProjectListMapViewAction({ mapView: !mapView }));
  };


  const [anchorElUser, setAnchorElUser] = useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <>
      <MainWraper>
        {isLoading ? <Loader /> : null}
        <Box>
          <Button
            label={!mapView ? "Map View" : "List View"}
            iconPosition="startIcon"
            iconType="location"
            sx={{
              minWidth: 120,
              height: 40,
            }}
            onClick={handleMapViewClick}
          />
        </Box>

        <div style={{ marginLeft: 20 }}>
          {/* <Typography color="white">Welcome: {name}</Typography> */}
          <Box sx={{ flexGrow: 0 }}>
          <Avatar onClick={handleOpenUserMenu}
                  sx={{
                    width: 40,
                    height: 40,
                    bgcolor: "gray",
                    color: "white",
                    fontSize: 16,
                  }}
                  src={imageUrl || undefined} 
                >
                  {!imageUrl && initials.toUpperCase()} 
                </Avatar>

            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
              PaperProps={{
                style: {
                  width: 220,
                },
              }}
            >
              <AccountSection>
                <Typography
                  style={{
                    marginLeft: 10,
                    fontWeight: 700,
                    fontSize: 12,
                    color: "#5E6C84",
                    marginBottom: 10,
                  }}
                >
                  Account
                </Typography>
                <div style={{ display: "flex", alignItems: "center" }}>
                <Avatar
                  sx={{
                    width: 40,
                    height: 40,
                    bgcolor: "gray",
                    color: "white",
                    fontSize: 16,
                  }}
                  src={imageUrl || undefined} 
                >
                  {!imageUrl && initials.toUpperCase()} 
                </Avatar>
                  <Typography style={{ marginLeft: 10, fontWeight: 700 }}>
                    {name}
                  </Typography>
                </div>
              </AccountSection>
              <MenuItem
                onClick={handleLogoutAndNavigation}
                style={{
                  padding: "15px 10px",
                }}
              >
                <Typography textAlign="center">Sign out</Typography>
              </MenuItem>
            </Menu>
          </Box>
        </div>
      </MainWraper>
    </>
  );
};

export default ProjectAppBarContent;
