import {createSlice} from "@reduxjs/toolkit";

const smartTrackingEditSlice = createSlice({
  name: "smartTrackingEdit",
  initialState: {
    data: {},
    loading: false,
  },
  reducers: {
    setSmartTrackingDataForEditAction(state, action) {
      return {
        ...state,
        data: action.payload.data
      }
    },
    editSmartTrackingPolygonAction(state) {
      return {
        ...state,
        loading: true
      }
    },
    editSmartTrackingPolygonSuccessAction() {
      return {
        data: {},
        loading: false,
      }
    }
  }
})

export const {setSmartTrackingDataForEditAction, editSmartTrackingPolygonAction, editSmartTrackingPolygonSuccessAction} = smartTrackingEditSlice.actions

export default smartTrackingEditSlice.reducer