import Grid from "@mui/material/Grid";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDesignListAction } from "../../redux/slices/design/list";
import DesignItem from "./DesignItem";
import { Box, Divider, Typography } from "@mui/material";
import { NavLink } from "react-router-dom";
import Button from "../Common/Button";


const DesignList = () => {
  const design = useSelector((state) => state.designList.data);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDesignListAction());
  }, [dispatch]);

  return (
    <>
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        pt={2}
        pb={2}
      >
        <Typography variant="h4">Design</Typography>
        <Box pt={2}>
          <Button
            label="Add Design"
            size="small"
            sx={{
              height: 37,
            }}
            component={NavLink}
            to="/design/add"
          />
        </Box>
      </Box>
      <Divider />

      {design && design?.id  ? (
        <Grid
          container
          direction="column"
          spacing={1}
          sx={{
            padding: "8px 8px 8px",
          }}
        >
            <Grid item xs key={design.id}>
              <DesignItem design={design} />
              {/*{!isEmpty(flightEdit) ? flightEdit.id === flight.id ?*/}
              {/*  <FlightEdit flight={flight}/> : <FlightItem flight={flight}/> : <FlightItem flight={flight}/>}*/}
            </Grid>
        </Grid>
      ) : (
        <Typography align="center" mt={4}>
          No Design Found
        </Typography>
      )}
      {/*<MaterialItem/>*/}
    </>
  );
};

export default DesignList;
