import {Avatar, ListItemAvatar, ListItemText, Menu, MenuItem, styled, Typography} from "@mui/material";
import {getIconComponentByTypeWithProps} from "../../utils/assets";
import DeleteDialog from "../Common/DeleteDialog";
import {useDispatch, useSelector} from "react-redux";
import {
  deleteSmartTrackingPolygonAction,
  setSmartTrackingPolygonDeleteIdAction
} from "../../redux/slices/smartTracking/delete";
import {setSmartTrackingDataForEditAction} from "../../redux/slices/smartTracking/edit";
import { useNavigate } from 'react-router-dom';

const AvatarStyled = styled(Avatar)(({theme}) => ({
  backgroundColor: theme.palette.white + "!important",
  color: theme.palette.text.link + "!important",
}))

const POLYGON_OPERATIONS = [
  // {key: "hide", title: "Hide"},
  {key: 'edit', title: 'Edit'},
  {key: 'delete', title: 'Delete'},
]

const SmartTrackingPolygonAction = ({polygon, handleToggleMenuClose, anchorEl}) => {

  const openMenu = Boolean(anchorEl);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleDelete = () => {
    dispatch(deleteSmartTrackingPolygonAction({id: polygon.id}))
  }

  const deleteSelectedId = useSelector(state => state.smartTrackingDelete.selectedId)
  const loading = useSelector(state => state.smartTrackingDelete.loading)

  const handleMenuClick = (key) => {
    if (key === "delete") {
      dispatch(setSmartTrackingPolygonDeleteIdAction({id: polygon.id}))
    }
    if (key === "edit") {
      dispatch(setSmartTrackingDataForEditAction({data: polygon}))
      navigate("/smart-tracking/edit")
    }
    handleToggleMenuClose()
  }

  return (
    <>
      <Menu
        id="smart-tracking-card-crud-dialog"
        aria-labelledby="smart-tracking-card-crud-dialog"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={openMenu}
        anchorEl={anchorEl}
        onClose={handleToggleMenuClose}
      >
        {POLYGON_OPERATIONS.map((operation, index) => (
          <MenuItem
            sx={{
              minHeight: 32,
              height: 32,
            }}
            // button
            onClick={() => handleMenuClick(operation.key)}
            key={`measurement-operation-${operation.title}-${index}`}
          >
            <ListItemAvatar>
              <AvatarStyled>
                {getIconComponentByTypeWithProps(operation.key)({
                  fontSize: "small",
                })}
              </AvatarStyled>
            </ListItemAvatar>
            <ListItemText
              primary={
                <Typography variant="subtitle2">{operation.title}</Typography>
              }
            />
          </MenuItem>
        ))}
      </Menu>
      <DeleteDialog
        item={polygon}
        selectedId={deleteSelectedId}
        onClose={() => dispatch(setSmartTrackingPolygonDeleteIdAction({id: null}))}
        handleDelete={handleDelete}
        loading={loading}
      />
    </>
  )
}

export default SmartTrackingPolygonAction
