import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import {
  getMeasurementListAction,
  updateMeasureDataListAction,
  resetMeasurementFiltersAction
} from "../../redux/slices/measurement/list";
import { measurementDeleteAction } from "../../redux/slices/measurement/delete";
import { resetCreateMeasureAction } from "../../redux/slices/measurement/create";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import MeasurementItem from "./Item";
import PrivateRequestBase from "../../redux/sagas/requests/rootRequest/PrivateRequestBase";
import { moveMeasurementLayersToTop , getMapBoxResourceId, setMapLayerLayoutProperty } from 'utils/layers/mapUtils';
import { getGeoJsonLayerBase } from "utils/layers/initialGeoJsonLayers";

import { updateGeoJsonLayerVisibilityAction } from "redux/slices/layers/geoJsonLayersSlice";
import { CircularProgress } from '@mui/material';





const MeasurementList = () => {

  const measurementData = useSelector(
    (state) => state.measurementList.data
  );


  const selectedFlightUuid = useSelector((state) => state.flightSelected.uuid);
  const selectedFlightId = useSelector((state) => state.flightSelected.id);
  const flights = useSelector((state) => state.flightList.data);
  const flightSelectedId = useSelector((state) => state.flightSelected.id);
  const [isMeasurementDone, setisMeasurementDone] = useState(false); 
  const loading = useSelector((state) => state.measurementList.loading);
  const dispatch = useDispatch();

  const [data, setData] = useState(null);
  const measurementUuid = measurementData?.filter((measure) => measure.processed !== true)?.[0]?.uuid; 
  const apiUrl = `${process.env.REACT_APP_BACKEND_URL}/events/measurement/${measurementUuid}`;

  const filterData = useSelector(
    (state) => state.measurementList.filterData
  );

useEffect(() => {
  const updateLayerVisibility = async () => {
    if (_.isUndefined(window.map)) return;

    const visibility = "visible";
    const baseGeoJsonLayerData = getGeoJsonLayerBase("measurement");
    const filteredMeasurementArray = filterData?.map(item => {
      const { geometry, flight } = item;
      const typeName = geometry.type.toLowerCase();
      return `user-measurement-${typeName}-${flight}`;
    });

    try {
      map?.getStyle()?.layers?.forEach(layer => {
      if (layer.id.includes('tracking') || layer.id.includes('polygons') ) {
        map.setLayoutProperty(layer.id, 'visibility', 'none');
      }
    });
    } catch (e) {};

    for (const subLayerKey of Object.keys(baseGeoJsonLayerData.subLayers)) {
      const mboxResId = `${baseGeoJsonLayerData.name}-${subLayerKey}-${selectedFlightId}`;
      const lastDashIndex = mboxResId.lastIndexOf("-");
      const result = mboxResId.substring(0, lastDashIndex);
      const layerId = getMapBoxResourceId(result, selectedFlightId, true);

      if (filteredMeasurementArray?.includes(mboxResId.toLowerCase())) {
        const success = await setMapLayerLayoutProperty(layerId, "visibility", visibility);
        if (success) {
          dispatch(updateGeoJsonLayerVisibilityAction({ key: layerId, visibility }));
        }
      } else if (!filteredMeasurementArray?.length) {
        const layerId = getMapBoxResourceId(`${baseGeoJsonLayerData.name}-${subLayerKey}`, selectedFlightId, true);

        const success = await setMapLayerLayoutProperty(layerId, "visibility", visibility);
        if (success) {
          dispatch(updateGeoJsonLayerVisibilityAction({ key: layerId, visibility }));
        }
      }
    }
  };

  updateLayerVisibility();
});

  
  useEffect(() => {
    if (flightSelectedId === null || flightSelectedId === undefined) {
      // Remove markers if no flight is selected
      dispatch(measurementDeleteAction());
    }
  }, [dispatch, flightSelectedId]);


 useEffect(() => {
    dispatch(resetCreateMeasureAction());
    dispatch(getMeasurementListAction());
    dispatch(resetMeasurementFiltersAction());
    moveMeasurementLayersToTop('user-measurement');
    // return () => {
    //   hideMeasurementLayers();
    // };
  }, [dispatch]);

  useEffect(() => {
    let fetchDataInterval;

    const fetchData = async () => {
      try {
        const open_socket =  measurementData.filter((measure) => measure.processed !== true); 
        clearInterval(fetchDataInterval);
        if(open_socket.length) {
        const response = await PrivateRequestBase("get", apiUrl);
        setData(response);
        if (response?.data?.data?.uuid) {
          dispatch(updateMeasureDataListAction({ data: response?.data?.data }));
          dispatch(getMeasurementListAction());
          // dispatch(resetCreateMeasureAction())
          setisMeasurementDone(true); 
          clearInterval(fetchDataInterval);
        }
      }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    const isProcessed =  measurementData?.length > 0 && measurementData?.filter((measure) => measure.processed !== true)

    if (!isMeasurementDone && isProcessed.length) {
      fetchDataInterval = setInterval(fetchData, 5000);
    }

    return () => clearInterval(fetchDataInterval);
  }, [selectedFlightUuid, dispatch, measurementData, apiUrl, isMeasurementDone, data, measurementUuid]);
  
  if (loading) {
    return <CircularProgress size={22} />;
  }

  return flights.length > 0 && measurementData?.length > 0 ? (
    <Grid
      container
      direction="column"
      style={{ boxShadow: "none", marginBottom: 10 }}
    >
      {measurementData.map((item) => (
        <Grid item key={item.id} style={{ width: "100%" }}>
          <MeasurementItem item={item} selectedFlightId={selectedFlightId} />
        </Grid>
      ))}
    </Grid>
  ) : (
    <Typography align="center" mt={4}>
      No Measurement Found
    </Typography>
  );
};

export default MeasurementList;
