import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import mapboxgl from "mapbox-gl";
import { Box } from "@mui/material";
import logo from "images/logo-large_new.png";
import { formatDate } from 'utils/layers/mapUtils';
import { stockpileReportAction }  from 'redux/slices/report';

const StockpileReport = () => {
  const selectedFlightId = useSelector((state) => state.flightSelected.uuid);
  const reportData = useSelector((state) => {
    return state.reportData.data;
}
);
  const dispatch = useDispatch();
  const tilesUrl = "mapbox://styles/mapbox/satellite-v9"; 

  const username = localStorage.getItem("name");
  const userEmail = localStorage.getItem("userEmail");

  const today = new Date();

  useEffect(() => {
     dispatch(stockpileReportAction());
  }, [selectedFlightId, dispatch]);

  useEffect(() => {
    if(reportData) {
      mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_PUBLIC_ACCESS_TOKEN; 
      const map = new mapboxgl.Map({
        container: "map", 
        style: tilesUrl, 
        center: [reportData.projectCoords?.[0] || 50.8, reportData.projectCoords?.[1] || 60.4], 
        zoom: 15, 
      });
  
      const nav = new mapboxgl.NavigationControl({
        showCompass: true,
        showZoom: false,
      });
  
      map.addControl(nav, "top-left");
      const modifiedGeoJSON = JSON.parse(reportData?.polygon_geojson);
      modifiedGeoJSON?.features?.forEach(function (feature) {
        if (feature.properties && feature.properties.color) {
          feature.properties.color = feature.properties.color.replace(/^#/, ''); 
        }
      });
      
      map.on("load", () => {
        map.addSource("user-change",
          {
              "type": "raster",
              "url": "mapbox://" + reportData?.tiles?.[1]?.[0]?.mapbox_tile_id,
              "tileSize": 256
          });
        map.addSource("user-tiles",
          {
              "type": "raster",
              "url": "mapbox://" + reportData?.tiles?.[0]?.[0]?.mapbox_tile_id,
              "tileSize": 256
          });

        map.addLayer({
          id: "user-tiles",
          type: "raster",
          source: "user-tiles",
          minzoom: 0,
          maxzoom: 22,
        });

        map.addLayer({
          id: "user-change",
          type: "raster",
          source: "user-change",
          minzoom: 0,
          maxzoom: 22,
          // paint: {
          //   "raster-opacity": transparency / 100,
          // },
        });
        map.addSource('custom-geojson-source', {
          type: 'geojson',
          data: modifiedGeoJSON
        });
            
        map.addLayer({
          id: 'custom-geojson-layer',
          type: 'fill', 
          source: 'custom-geojson-source',
          paint: {
            'fill-color': [
              'case',
              ['==', ['get', 'color'], ''], 'transparent', 
              ['get', 'color'],
            ],
            'fill-opacity': 0.5,
          },
        });
      });
      return () => map.remove();
    }
   
   
  }, [reportData, reportData?.projectCoords]);

  return (
    <>{
      reportData  ?
    <Box style={{ width: "11in", height: "8.45in"}}>
      <div style={{ display: "flex", flexDirection: "row", border: '1px solid #000', borderRadius: '4px' }}>
        <div style={{ width: 775, margin: "0px 2px 0px 12px" }}>
          <div className="roundborder" id="map" style={{ height: 680 }}>
            { reportData?.projectCoords ? 'loading map ...' : reportData?.message }
          </div>
          
          <div style={{ 
            // marginLeft: '-10px',
            marginRight: '-10px',
            marginTop: '5px',
            marginBottom: '5px' 
          }}
            >
          <div
            className="row"
            style={{
              height: "100px",
            }}
          >
          <div
            className="column"
            style={{
              marginLeft: '-10px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img
              src={logo}
              style={{
                height: '70%',
                width: '70%',
                display: 'block',
                marginLeft: 'auto',
                marginRight: 'auto',
                marginTop: '3px',
              }}
            />
          </div>
            <div
              className="column"
              style={{ width: '247px', fontSize: 'x-small' }}
            >
              <table className="reporttableBottom" style={{ textAlign: "left" }}>
                <tbody style={{  }}>
                  <tr>
                    <td style={{  }}>
                      <b>GCP Residuals RMSE:</b>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ }}>
                      <b>Ground Sample Distance: </b>
                    </td>
                  </tr>
                  <tr>
                    <td style={{}}>
                      <b>Report Generated: {formatDate(today)}</b>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ }}>
                      <b>
                        User: {username} |{" "}
                        <span style={{ color: " #5897fb" }}>
                        {userEmail}
                        </span>
                      </b>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

          <div className="column" style={{ width: '228px' }}>
              <div style={{textAlign: 'justify', fontSize: 'x-small'}}>
                The information contained herein may be compiled from numerous
                third-party materials that are subject to periodic change without prior
                notification. While every effort has been made by the Civil Tracker
                to ensure the accuracy of the information presented at the time of
                publication, Civil Tracker assumes no liability for any errors,
                omissions, or inaccuracies in the third-party material.
              </div>
            </div>
          </div>
                </div>
        </div>
        <div style={{
           width: 273,
            margin: "0px 4px 0px 12px"
           }}
            >
              {/* Progress table */}
              <div style={{
           width: 273,
           height: '80.5%',
            // margin: "0px 4px 0px 12px"
           }}
            >
          <div
            className="tbldiv roundborder"
            style={{
              width: "260px",
              marginLeft: "2px",
              marginTop: "4px",
              padding: "5px",
              // height: "64%"
            }}
          >
        <div style={{}}>

            <table className="reporttable">
            <thead>
              <tr className="table-title">
                <th colSpan={3} align="left" style={{ textAlign: "center" }}>Stockpile Table</th>
              </tr>

              <tr style={{}}>
                <th style={{ width: '20%' }}></th> 
                <th>Name</th>
                <th>Net Volume ({reportData?.unit}<sup>3</sup>)</th>
              </tr>
            </thead>
            <tbody>
              {reportData?.stockpile_info &&
                reportData.stockpile_info.map((item, index) => (
                  <tr key={index} style={{ fontWeight: "bold", textAlign: "left" }}>
                    <td style={{ color: item.color }}>
                      {/* <div style={{ width: '20px', height: '20px', backgroundColor: item.color }}></div> */}
                      <div
                      style={{
                        border: `2px solid ${item.color}`,
                        height: "10px",
                        width: "10px",
                      }}
                    ></div>
                    </td>
                    <td>{item.name}</td>
                    <td>{item.tin_base_volume.toFixed(2)}</td>
                  </tr>
                ))
              }
            </tbody>
          </table>

            </div>
            </div>
          </div>
          <div className="roundborder progressDiv">
            <div
              style={{
                fontWeight: "bold",
                marginTop: "7px",
                textAlign: "center",
                fontSize: "x-small",
              }}
            >
              <h2>{reportData?.project_name}</h2>
            </div>
            <div
              style={{
                fontWeight: "bold",
                marginTop: "7px",
                textAlign: "center",
                fontSize: "x-small",
              }}
            >
              Stockpile report showing
            </div>
            <div
              style={{
                fontSize: "x-small",
                fontWeight: "bold",
                textAlign: "center",
                marginTop: "5px",
              }}
            >
              Flight Name: ({reportData?.flight_name})
            </div>
            <div
              style={{
                fontSize: "x-small",
                fontWeight: "bold",
                textAlign: "center",
                marginTop: "5px",
              }}
            >
              
            </div>

            <div
              style={{
                fontSize: "x-small",
                fontWeight: "bold",
                textAlign: "center",
                marginTop: "5px",
              }}
            >
             Flight Date: ({reportData?.current_flight_date})
            </div>
          </div>
        </div>
      </div>
    </Box> :
     <div> </div>
     }
    </>
  );
};

export default StockpileReport;
