import {styled, TextField as MuiTextField} from "@mui/material";

const TextFieldStyled = styled(MuiTextField)(() => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: 8
  }
}))


const TextField = ({name,label, error, value, onChange, required=false, ...props}) => {
  return(
    <TextFieldStyled
      name={name}
    label={label}
    color="secondary"
    required={required}
    value={value}
    onChange={onChange}
    onFocus={(e) => e.stopPropagation()}
    error={!!error}
    InputLabelProps={{shrink: true }}
    helperText={error}
    {...props}
    />
  )
}

export default TextField