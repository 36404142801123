import Box from "@mui/material/Box";
import { useDropzone } from "react-dropzone";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { getIconComponentByType } from "../../utils/assets";
import { useEffect, useState, useRef } from "react";
import { styled } from "@mui/material/styles";

const PaperStyled = styled(Paper)(() => ({
  width: "100%",
  height: 184,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
}));

const FileDropBox = ({
  name,
  label,
  acceptFileType,
  onFileDrop,
  multiple = false,
  error = "",
  value = null,
  message,
  is_file_accepted = true,
  resetFileCount,
  reset,
  maxSize,
  isGCPFile = false, // New prop added here
  ...props
}) => {
  const [errors, setErrors] = useState("");
  const [acceptMessage, setAcceptMessage] = useState("");
  const fileCount = useRef(0);

  const handleFileReject = (fileRejections) => {
    setErrors(fileRejections[0].errors[0].message);
    setAcceptMessage("");
  };

  const handleFileAccept = (acceptedFiles) => {
    const file = acceptedFiles[0];
    if (maxSize && file.size > maxSize) {
      setErrors(`File size exceeds ${maxSize / 1024 / 1024}MB`);
    } else {
      setErrors("");
      onFileDrop(acceptedFiles);

      // Reset the file count if it's a GCP file
      if (isGCPFile) {
        fileCount.current = 1;
      } else {
        fileCount.current += acceptedFiles.length;
      }

      if (is_file_accepted) {
        setAcceptMessage(`${fileCount.current} file${fileCount.current > 1 ? 's' : ''} selected`);
      }
    }
  };

  useEffect(() => {
    setErrors(error);
    if (!value) {
      setAcceptMessage("");
    }
    if (resetFileCount) {
      fileCount.current = 0;
      setAcceptMessage("");
    }
  }, [error, value, resetFileCount]);

  useEffect(() => {
    if (reset) {
      setAcceptMessage("");
      fileCount.current = 0;
    }
  }, [reset]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: acceptFileType,
    onDropAccepted: handleFileAccept,
    onDropRejected: handleFileReject,
    multiple: multiple,
    ...props,
  });

  const { ref, ...rootProps } = getRootProps();

  return (
    <Box ref={ref}>
      <PaperStyled {...rootProps} variant="outlined">
        <input {...getInputProps()} name={name} />
        {getIconComponentByType("cloudUpload")}
        <Typography variant="h6">Drag & Drop</Typography>
        <Typography variant="h6">{label}</Typography>
        <Typography>{message}</Typography>
        {errors && (
          <Typography
            style={{
              color: "red",
              padding: 5,
              margin: 0,
              fontSize: 14,
            }}
          >
            {errors}
          </Typography>
        )}
        {acceptMessage && (
          <Typography
            noWrap
            style={{ color: "green", padding: 5, margin: 0, fontSize: 14 }}
          >
            {acceptMessage.length > 20
              ? `${acceptMessage.slice(0, 20)}...`
              : acceptMessage}
          </Typography>
        )}
      </PaperStyled>
    </Box>
  );
};

export default FileDropBox;
