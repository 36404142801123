import logo from "../../../images/logo-long.svg"
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";

import {Link} from "react-router-dom";

const GridStyled = styled(Grid)(() => ({
  position: "absolute",
  top: "10px",
  zIndex: 1,
  padding: "0 20px"
}))

const ButtonStyled = styled(Button)(() => ({
  width: 123,
  height: 34,
  borderRadius: "17px",
  boxShadow: "0px 4px 7px -1px rgba(0, 0, 0, 0.11), 0px 2px 4px -1px rgba(0, 0, 0, 0.07)",
}))

const BasicHeader = ({buttonLabel, buttonLink}) => {
  return (
      <GridStyled justifyContent="space-between" container alignItems="center">
        <Grid item> <img src={logo} alt="logo" width={200} height={34}/></Grid>
        <Grid item><ButtonStyled variant="contained" component={Link} to={buttonLink}>{buttonLabel}</ButtonStyled></Grid>
      </GridStyled>

  )
}

export default BasicHeader;