import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import mapboxgl from "mapbox-gl";
import { Box } from "@mui/material";
import logo from "images/logo-large_new.png";
import Scale from "../../images/scale.png";
import PrivateRequestBase from "../../redux/sagas/requests/rootRequest/PrivateRequestBase";
import { formatDate } from 'utils/layers/mapUtils'

const Report = () => {
  const selectedFlightId = useSelector((state) => state.flightSelected.uuid);
  const tilesUrl = "mapbox://styles/mapbox/satellite-v9"; 
  const [reportData, setreportData] = useState();

  const username = localStorage.getItem("name");
  const userEmail = localStorage.getItem("userEmail");

  const today = new Date();

  useEffect(() => {
    PrivateRequestBase("get", `api/generate_report/${selectedFlightId}`)
    .then((response) => {
      if (response.status  !== 200) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      return response;
    })
    .then((result) => {
      setreportData(result?.data);
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
    });
  }, [selectedFlightId]);

  useEffect(() => {
    if(reportData?.projectCoords) {
      mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_PUBLIC_ACCESS_TOKEN; 
      const map = new mapboxgl.Map({
        container: "map", 
        style: tilesUrl, 
        center: [reportData.projectCoords?.[0] || 50.8, reportData.projectCoords?.[1] || 60.4], 
        zoom: 15, 
      });
  
      const nav = new mapboxgl.NavigationControl({
        showCompass: true,
        showZoom: false,
      });
  
      map.addControl(nav, "top-left");
      const modifiedGeoJSON = JSON.parse(reportData?.polygon_geojson);
      modifiedGeoJSON?.features?.forEach(function (feature) {
        if (feature.properties && feature.properties.color) {
          feature.properties.color = feature.properties.color.replace(/^#/, ''); 
        }
      });
      map.on('load', function() {
        // to be un-commented once t-tiler is functional
        //  const url =  `https://25p5jflglc.execute-api.ca-central-1.amazonaws.com/cog/tiles/{z}/{x}/{y}/WebMercatorQuad&url=${cogUrl}&bidx=1&bidx=2&bidx=4`;
        // map.addSource('tile-source', {
        //   type: 'raster',
        //   tiles: [
        //     'https://25p5jflglc.execute-api.ca-central-1.amazonaws.com/cog/tiles/{z}/{x}/{y}/?TileMatrixSetId=WebMercatorQuad&scale=1&url=s3://civil-tracker-staging/media/flights/0afc4448-c78e-4acd-a6e0-35692ac98dfc/processed/hillshade_cog.tif'
        //   ],
        //   tileSize: 256,
        // });
      
        // map.addLayer({
        //   id: 'custom-tiles',
        //   type: 'raster',
        //   source: 'tile-source',
        // });
      });
      
      map.on("load", () => {
  
        map.addSource("user-change",
          {
              "type": "raster",
              "url": "mapbox://" + reportData?.tiles?.[1][0]?.mapbox_tile_id,
              "tileSize": 256
          });
        map.addSource("user-tiles",
          {
              "type": "raster",
              "url": "mapbox://" + reportData?.tiles?.[0][0]?.mapbox_tile_id,
              "tileSize": 256
          });

        map.addLayer({
          id: "user-tiles",
          type: "raster",
          source: "user-tiles",
          minzoom: 0,
          maxzoom: 22,
        });

        map.addLayer({
          id: "user-change",
          type: "raster",
          source: "user-change",
          minzoom: 0,
          maxzoom: 22,
          // paint: {
          //   "raster-opacity": transparency / 100,
          // },
        });
        map.addSource('custom-geojson-source', {
          type: 'geojson',
          data: modifiedGeoJSON
        });
            
        map.addLayer({
          id: 'custom-geojson-layer',
          type: 'fill', 
          source: 'custom-geojson-source',
          paint: {
            'fill-color': [
              'case',
              ['==', ['get', 'color'], ''], 'transparent', 
              ['get', 'color'],
            ],
            'fill-opacity': 0.5,
          },
        });
      });
      return () => map.remove();
    }
   
   
  }, [reportData?.projectCoords]);

  return (
    <>{
      reportData  ?
    <Box style={{ width: "11in", height: "8.45in"}}>
      <div style={{ display: "flex", flexDirection: "row", border: '1px solid #000', borderRadius: '4px' }}>
        <div style={{ width: 775, margin: "0px 2px 0px 12px" }}>
          <div className="roundborder" id="map" style={{ height: 680 }}>
            { reportData?.projectCoords ? 'loading map ...' : reportData?.message }
          </div>
          
          <div style={{ 
            // marginLeft: '-10px',
            marginRight: '-10px',
            marginTop: '5px',
            marginBottom: '5px' 
          }}
            >
          <div
            className="row"
            style={{
              height: "100px",
            }}
          >
          <div
            className="column"
            style={{
              marginLeft: '-10px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img
              src={logo}
              style={{
                height: '70%',
                width: '70%',
                display: 'block',
                marginLeft: 'auto',
                marginRight: 'auto',
                marginTop: '3px',
              }}
            />
          </div>
            <div
              className="column"
              style={{ width: '247px', fontSize: 'x-small' }}
            >
              <table className="reporttableBottom" style={{ textAlign: "left" }}>
                <tbody style={{  }}>
                  <tr>
                    <td style={{  }}>
                      <b>GCP Residuals RMSE:</b>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ }}>
                      <b>Ground Sample Distance: </b>
                    </td>
                  </tr>
                  <tr>
                    <td style={{}}>
                      <b>Report Generated: {formatDate(today)}</b>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ }}>
                      <b>
                        User: {username} |{" "}
                        <span style={{ color: " #5897fb" }}>
                        {userEmail}
                        </span>
                      </b>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="column" style={{ paddingRight: '31px !important', width: '228' }}>
              <img
                alt=""
                src={Scale}
                style={{ height: "50%", width: "100%", marginTop: "24px" }}
              />
            </div>
          </div>
                </div>
        </div>
        <div style={{
           width: 273,
            margin: "0px 4px 0px 12px"
           }}
            >
              {/* Progress table */}
              <div style={{
           width: 273,
           height: '80%',
           position: 'relative'
            // margin: "0px 4px 0px 12px"
           }}
            >
          <div
            className="tbldiv roundborder"
            style={{
              width: "260px",
              marginLeft: "2px",
              marginTop: "4px",
              padding: "5px",
              // height: "64%"
            }}
          >
        <div style={{}}>

            <table className="reporttable">
              <thead>
                <tr className="table-title">
                  <th colSpan={4} align="left" style={{ textAlign: "center" }}>Progress Table</th>
                </tr>

                <tr style={{  }}>
                  <th>Material</th>
                  <th>
                    Cut ({reportData?.unit}<sup>3</sup>)
                  </th>
                  <th>
                    Fill ({reportData?.unit}<sup>3</sup>)
                  </th>
                  <th>
                    Net ({reportData?.unit}<sup>3</sup>)
                  </th>
                </tr>
              </thead>
              <tbody>
              {reportData?.project_progress_c && 
              Object.keys(reportData?.project_progress_c).map((material) => (
          <tr key={material} style={{ fontWeight: "bold", textAlign: "left" }}>
            <td>{material}</td>
            <td>{(Math.abs(reportData?.project_progress_c?.[material].loss).toFixed(0))}</td>
            <td>{(Math.abs(reportData?.project_progress_c?.[material].gain).toFixed(0))}</td>
            <td>{(Math.abs(reportData?.project_progress_c?.[material].volume).toFixed(0))}</td>
          </tr>
        ))}
        </tbody>
            </table>
            </div>
            </div>

              {/* Project total table */}
          <div
            className="tbldiv roundborder"
            style={{
              width: "260px",
              marginLeft: "2px",
              marginTop: "4px",
              padding: "5px",
              // height: "138px"
            }}
          >
            <table className="reporttable">
              <thead>
                <tr className="table-title">
                  <th align="left" colSpan={4} style={{ textAlign: 'center' }}>Project Total Table</th>
                </tr>
            
                <tr style={{ }}>
                  <th>Material</th>
                  <th>
                    Cut({reportData?.unit}<sup>3</sup>)
                  </th>
                  <th>
                    Fill({reportData?.unit}<sup>3</sup>)
                  </th>
                  <th>
                    Net({reportData?.unit}<sup>3</sup>)
                  </th>
                </tr>
              </thead>
              <tbody>
                  {reportData?.project_total_table &&
                  Object.keys(reportData?.project_total_table).map((material) => (
                      <tr key={material} style={{ fontWeight: "bold" }} >
                        <td>{material}</td>
                        <td>{(Math.abs(reportData?.project_progress_c?.[material].loss).toFixed(0))}</td>
                        <td>{(Math.abs(reportData?.project_progress_c?.[material].gain).toFixed(0))}</td>
                        <td>{(Math.abs(reportData?.project_progress_c?.[material].volume).toFixed(0))}</td>
                      </tr>
                    ))}
              </tbody>
            </table>
          </div>
                    {/* Polygon table */}
          <div
            className="tbldiv roundborder"
            style={{
              width: "260px",
              marginLeft: "2px",
              marginTop: "4px",
              padding: "5px",
              // height: "142px"
            }}
          >
            <table className="reporttable">
              <thead>
                <tr className="table-title">
                  <th align="left" colSpan={3} style={{ textAlign: "center" }}>Polygon Table</th>
                </tr>
           
                <tr style={{  }}>
                  <th>ID</th>
                  <th>Area ({reportData?.area_unit}<sup>2 </sup>)</th>
                  <th>Volume ({reportData?.unit} <sup>3</sup> )</th>
                </tr>
              </thead>
              <tbody>
              {reportData && reportData?.polygon_info?.map((item, index) => (
                <tr key={index} style={{ fontWeight: "bold" }}>
                  <td>{item.name}</td>
                  <td>{Math.abs(item.area.toFixed(0))}</td>
                  <td>{item.stats.net_volume.toFixed(0)}</td>
                </tr>
              ))}
              </tbody>
            </table>
          </div>

          <div className="roundborder legendReportDiv"> {/* legend start*/}
            <table className="reporttable">
              <thead>
                <tr className="table-title">
                  <th align="left" colSpan={3} style={{ textAlign: "center" }}>Legend</th>
                </tr>
              
             
              </thead>
              <tbody>
                {reportData && reportData?.usermaterial?.map((item, index) => (
                <tr key={index} style={{ fontWeight: "bold"}} className="table-title">
                  <td>{item.name}</td>
                  <td></td>
                  <td>  <div
                      style={{
                        border: `2px solid ${item.color}`,
                        height: "10px",
                        width: "10px",
                      }}
                    ></div></td>
                </tr>
              ))}
 
   
              </tbody>
            </table>
          </div> {/* legend right ends here */}
            
          </div>
          <Box style={{ height: '20%', position: 'relative' }}> {/* lower right ends here */}


          <div className="roundborder infoDiv"> {/* project info start here*/}        
            <div
              style={{
                fontWeight: "bold",
                marginTop: "7px",
                textAlign: "center",
                fontSize: "x-small",
              }}
            >
              <h2>{reportData?.project_name}</h2>
            </div>
            <div
              style={{
                fontWeight: "bold",
                marginTop: "7px",
                textAlign: "center",
                fontSize: "x-small",
              }}
            >
              Progress Report Showing
            </div>
            <div
              style={{
                fontSize: "x-small",
                fontWeight: "bold",
                textAlign: "center",
                marginTop: "5px",
              }}
            >
              Current As-Built ({reportData?.current_flight_date})
            </div>
            <div
              style={{
                fontSize: "x-small",
                fontWeight: "bold",
                textAlign: "center",
                marginTop: "5px",
              }}
            >
              VS
            </div>
            <div
              style={{
                fontSize: "x-small",
                fontWeight: "bold",
                textAlign: "center",
                marginTop: "5px",
              }}
            >
              Previous As-Built ({reportData?.early_flight_date})
            </div>
          </div> {/* project info ends here*/}  

          </Box> {/* lower right ends here*/}
          
        </div>
      </div>
    </Box> :
     <div> </div>
     }
    </>
  );
};

export default Report;
