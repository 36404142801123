// import { useDispatch, useSelector } from "react-redux";
import { useSelector } from "react-redux";
import { useEffect, useRef } from "react";
import mapboxgl from "mapbox-gl";


import "mapbox-gl/dist/mapbox-gl.css";
import { styled } from "@mui/material/styles";

import Box from "@mui/material/Box";
// import {retainMapStateAfterRefreshAction} from "../../redux/slices/managementSlice";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
// import { useNavigate } from 'react-router-dom';


const BoxStyled = styled(Box)(({ theme }) => ({
  height: "calc(100vh - 64px)",
  overflow: "hidden",
  [theme.breakpoints.down("sm")]: {
    height: "calc(100vh - 54px)",
  },
}));

// const MapComponent = ({ selectedProjectId }) => {
const MapComponent = () => {

  const map = useRef(null);
  const mapContainer = useRef(null);
  const mapState = useSelector((state) => state.mapState);
  // const flightSelectedId = useSelector((state) => state.flightSelected.id);
  // const dispatch = useDispatch();

  // useEffect(() => {
  //   if (selectedProjectId && map.current) {
  //     map.current.on("idle", () => {
  //       const selectedProjectFlights = mapState.projects.find(
  //         (project) => project.id === selectedProjectId
  //       )?.flights;

  //       // Remove markers if no flights are present for the selected project
  //       if (!selectedProjectFlights || selectedProjectFlights.length === 0) {
  //         if (map.current && map.current._markers) {
  //           Object.values(map.current._markers).forEach((marker) => {
  //             marker.remove();
  //           });
  //         }
  //       }
  //     });
  //   }
  // }, [mapState, selectedProjectId]);

  // const mapCheck = () => {
  //   const isLoaded = map.current.loaded();

  //   if (isLoaded) {
  //     dispatch(retainMapStateAfterRefreshAction({ history: history }));
  //   } else {
  //     mapCheck();
  //   }
  // };

  useEffect(() => {
    if (map.current) return;

    const mapData = {
      container: mapContainer.current,
        // style: 'mapbox://styles/mapbox/satellite-streets-v12',

      style: {
        version: 8,
        sources: {
          "raster-tiles": {
            type: "raster",
            url: "mapbox://mapbox.satellite",
            tileSize: 256,
          },
        },
        layers: [
          {
            id: "simple-tiles",
            type: "raster",
            source: "raster-tiles",
            minzoom: 0,
            maxzoom: 22,
          },
        ],
      },
      center: [mapState.lng, mapState.lat],
      zoom: mapState.zoom,
    };

    mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_PUBLIC_ACCESS_TOKEN;
  // mapboxgl.accessToken = 'pk.eyJ1IjoibWFwYm94IiwiYSI6ImNqeGZodGF1dDAxMHgzY28yc2tkaDVvdWQifQ.xvxOsRhGZfrXk8T9Oq9EvQ';

    map.current = new mapboxgl.Map(mapData);
    map.current.addControl(new mapboxgl.NavigationControl());
    window.map = map.current;

    // window.map?.on("load", () => {
    //         window.map?.loadImage("assets/images/pano-icon.png", (error, image) => {
    //               if (error) {
    //                 console.error('Error loading the pano icon:', error);
    //                 return;
    //               }
    //         });
    // });
    // return () => map.current.remove();
  }, []);

  // useEffect(() => {
  //   if (!map.current) return; // wait for map to initialize
  //   map.current.on("idle", (idleState) => {
  //     if (
  //       map.current.getZoom() !== mapState.zoom ||
  //       map.current.getCenter().lng !== mapState.lng ||
  //       map.current.getCenter().lat !== mapState.lat
  //     ) {
  //       dispatch(
  //         setMapStateAction({
  //           lng: map.current.getCenter().lng,
  //           lat: map.current.getCenter().lat,
  //           zoom: map.current.getZoom(),
  //         })
  //       );
  //     } else {
  //       return map.current;
  //     }
  //   });

  //   map.current.on("remove", () => {
  //     console.log("A remove event occurred.");
  //   });

  //   map.current.on("load", mapCheck);
  //   map.current.on("render", (event) => {
  //     event.target.resize();
  //   });
  // }, []);


  // const mapContainerTwo = useRef(null);
  // const mapTwo = useRef(null);

  // Mapbox Two
  // mapboxgl.accessToken = 'pk.eyJ1IjoibWFwYm94IiwiYSI6ImNqeGZodGF1dDAxMHgzY28yc2tkaDVvdWQifQ.xvxOsRhGZfrXk8T9Oq9EvQ';
  // useEffect(() => {
  //   if (mapTwo.current) return; // Initialize map only once
  //   mapTwo.current = new mapboxgl.Map({
  //     container: mapContainerTwo.current,
  //     style: "mapbox://styles/mapbox/streets-v11",
  //     center: [-74.5, 40], // Starting position [lng, lat]
  //     zoom: 9, // Starting zoom level
  //   });
  // }, []);

  
  return <>
  <BoxStyled ref={mapContainer} />
  </>;
};

export default MapComponent;
