import { call, put, select } from "redux-saga/effects";
import { getLineworkListAction, setLineworkListAction, setLineworkListError, resetDeleteLineworkAction } from "redux/slices/linework/list";
import { resetLineworkFileAction, setLineworkCreateError } from "redux/slices/linework/create";
import { getLineworkListRequest, createLineworkRequest, getLineworkKLRequest, deleteLineworkKLRequest } from "redux/sagas/requests/lineworkRequest";
import mapboxgl from "mapbox-gl";

export function* getLineworkListHandler() {
  try {
    const map = window.map;
    const selectedProjectuuId = yield select((state) => state.selectedProject.uuid);
    const response = yield call(getLineworkListRequest, selectedProjectuuId);
    const lineworkList = response?.data?.data;

    yield put(setLineworkListAction(lineworkList));

    if (lineworkList && lineworkList.length > 0) {
      for (const linework of lineworkList) {
        const geojson = yield call(getLineworkKLRequest, linework?.uuid);

        geojson.data.features.forEach(feature => {
      if (feature.properties) {
        const style = convertColor(feature);
        feature.properties.fillColor = style.fillColor || null;
        feature.properties.borderColor = style.borderColor || null;
        feature.properties.borderWidth = style.borderWidth || null;
        feature.properties.lineColor = style.lineColor || null;
        feature.properties.lineWidth = style.lineWidth || null;
        feature.properties.color = style.color || null;
      }
    });

        const baseId = 'linework';
        const sourceId = `${baseId}-source-${linework?.uuid}`;
        const pointLayerId = `${baseId}-point-${linework?.uuid}`;
        const lineLayerId = `${baseId}-line-${linework?.uuid}`;
        const polygonLayerId = `${baseId}-polygon-${linework?.uuid}`;

        map.addSource(sourceId, {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: geojson?.data?.features || []
          }
        });

      map.addLayer({
      id: polygonLayerId,
      type: 'fill',
      source: sourceId,
      paint: {
        'fill-color': ['get', 'fillColor'],
      },
      filter: ['==', '$type', 'Polygon']
    });

    map.addLayer({
      id: `${polygonLayerId}-outline`,
      type: 'line',
      source: sourceId,
      paint: {
        'line-color': ['get', 'borderColor'],
        'line-width': ['get', 'borderWidth'],
      },
      filter: ['==', '$type', 'Polygon']
    });

      map.addLayer({
        id: 'outlineLayerId',
        type: 'line',
        source: polygonLayerId,
        layout: {},
        paint: {
          'line-color': ['get', 'borderColor'],
          'line-width': ['get', 'borderWidth']
        },
        filter: ['==', '$type', 'LineString']
      });

        map.addLayer({
          id: lineLayerId,
          type: 'line',
          source: sourceId,
           paint: {
            'line-color': ['get', 'lineColor'],
            'line-width': ['get', 'lineWidth'],
          },
          filter: ['==', '$type', 'LineString']
        });

        map.addLayer({
          id: pointLayerId,
          type: 'circle',
          source: sourceId,
          paint: {
            'circle-radius': 5,
            'circle-color': ['get', 'color'],
          },
          filter: ['==', '$type', 'Point']
        });

        const showFeatureInfo = (e) => {
          const features = map.queryRenderedFeatures(e.point, {
            layers: [pointLayerId, polygonLayerId, lineLayerId]
          });
          if (features.length > 0) {
            const feature = features[0];
            const properties = feature.properties;
            const popupContent = Object.keys(properties).map(key => `<strong>${key}:</strong> ${properties[key]}`).join('<br>');

            new mapboxgl.Popup()
              .setLngLat(e.lngLat)
              .setHTML(`${popupContent}`)
              .addTo(map);
          }
        };

        map.on('click', pointLayerId, showFeatureInfo);
        map.on('click', polygonLayerId, showFeatureInfo);
        map.on('click', lineLayerId, showFeatureInfo);
      }
    }
  } catch (error) {
    yield put(setLineworkListError(error.message));
  }
}


// const getNextLayerId = (baseId) => {
//   let counter = 1;
//   const map = window.map;
//   while (map.getLayer(`${baseId}${counter}`)) {
//     counter++;
//   }
//   return `${baseId}${counter}`;
// }
const convertColor = (feature) => {
  const { poly_fill, line_color, line_width, poly_color } = feature.properties;

  if (feature.geometry.type === 'Polygon') {
    const fillColor = poly_fill === '0' ? 'rgba(0,0,0,0)' : `#${poly_color}`;
    const borderColor = `#${line_color}`;
    const borderWidth = line_width ? parseFloat(line_width) : 1;

    return {
      fillColor,
      borderColor,
      borderWidth,
    };
  } else if (feature.geometry.type === 'LineString') {
    return {
      lineColor: `#${line_color}`,
      lineWidth: line_width ? parseFloat(line_width) : 1,
    };
  } else if (feature.geometry.type === 'Point') {
    return {
      color: `#${poly_color}`,
    };
  }

  return {
    color: '#000000',
  };
};



export function* getLineworkVisibilityHandler(action) {
  try {
    const { id, uuid } = action.payload;
    const visibility = yield select(state => state.lineworkList?.visibleLayers?.[id]);
    const map = window.map;

    const layers = [
      `linework-line-${uuid}`,
      `linework-point-${uuid}`,
      `linework-polygon-${uuid}`
    ];

    layers.forEach(layerId => {
      if (map.getLayer(layerId)) {
        const newVisibility = visibility ? 'none' : 'visible';
        map.setLayoutProperty(layerId, 'visibility', newVisibility);
      }
    });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error handling visibility:', error);
  }
}


export function* createLineworkHandler(action) {
  try {
    const { formData } = action.payload;
    yield call(createLineworkRequest, formData);
    navigate("/linework");
    yield call(getLineworkListAction());

    yield put(resetLineworkFileAction());

  } catch (error) {
    yield put(setLineworkCreateError(error.message));
  }
}


export function* deleteLineworkHandler(action) {
  try {
    const map = window.map;
    const selectedProjectuuId = yield select((state) => state.selectedProject.uuid);
    const uuid = action?.payload?.uuid;

    const baseId = 'linework';
    const sourceId = `${baseId}-source-${uuid}`;
    const pointLayerId = `${baseId}-point-${uuid}`;
    const lineLayerId = `${baseId}-line-${uuid}`;
    const polygonLayerId = `${baseId}-polygon-${uuid}`;
    const polygonOutlineLayerId = `${polygonLayerId}-outline`;

    if (map.getLayer(pointLayerId)) {
      map.removeLayer(pointLayerId);
    }
    if (map.getLayer(lineLayerId)) {
      map.removeLayer(lineLayerId);
    }
     if (map.getLayer(polygonOutlineLayerId)) {
      map.removeLayer(polygonOutlineLayerId);
    }
    if (map.getLayer(polygonLayerId)) {
      map.removeLayer(polygonLayerId);
    }
    if (map.getSource(sourceId)) {
      map.removeSource(sourceId);
    }

    yield call(deleteLineworkKLRequest, uuid);
    const response = yield call(getLineworkListRequest, selectedProjectuuId);
    const lineworkList = response?.data?.data;
    yield put(resetDeleteLineworkAction());
    yield put(setLineworkListAction(lineworkList));
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('error', e);
  }
}
