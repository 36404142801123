import { call, put } from "redux-saga/effects";
import {
  resetPasswordConfirmRequest,
  resetPasswordRequest,
  authorizeLinkRequest,
} from "../../requests/basic/resetPasswordRequest";
import {
  setResetPasswordServerErrorsAction,
  setResetPasswordSuccessAction,
  sertAuthorizeLink,
} from "../../../slices/basic/resetPasswordSlice";

// export function* resetPasswordHandler(action) {
//   try {
//     const response = yield call(resetPasswordRequest, { ...action.payload });
//     debugger;
//     yield put(setResetPasswordSuccessAction());
//   } catch (error) {
//     if (error.response.status !== 500)
//       yield put(
//         setResetPasswordServerErrorsAction({ errors: error.response.data })
//       );
//     console.log(error);
//   }
// }

export function* resetPasswordHandler(action) {
  try {
    yield call(resetPasswordRequest, { ...action.payload });

    yield put(setResetPasswordSuccessAction());
  } catch (error) {
    if (error.response && error.response.status !== 500) {
      yield put(
        setResetPasswordServerErrorsAction({ errors: error.response.data })
      );
    }
    // eslint-disable-next-line no-console
    console.log(error);
  }
}

export function* resetPasswordConfirmHandler(action) {
  try {
    yield call(resetPasswordConfirmRequest, action.payload);
    yield put(setResetPasswordSuccessAction());
  } catch (error) {
    if (error.response && error.response.status !== 500)
      yield put(
        setResetPasswordServerErrorsAction({ errors: error.response.data })
      );
    // eslint-disable-next-line no-console
    console.log('password error', error);
  }
}

export function* authorizeRequestHandler(action) {
  try {
    yield call(authorizeLinkRequest, action.payload);
    yield put(sertAuthorizeLink());
  } catch (error) {
    if (
      error.response &&
      (error.response.status !== 302 || error.response.status === 401)
    )
      yield put(
        setResetPasswordServerErrorsAction({
          errors: error.response.data || " Something went wrong",
        })
      );
    // eslint-disable-next-line no-console
    console.log("Error", error.response);
  }
}
