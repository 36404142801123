import {createSlice} from "@reduxjs/toolkit";

const projectCreateOrEditSlice = createSlice({
  name: "projectCreateState",
  initialState: {
    loading: false,
    createOpenPopup: false,
    editOpenPopup: false,
    editSelectedProjectId: null
  },
  reducers: {
    setProjectCreateOpenPopupAction(state, action) {
      return {
        ...state,
        createOpenPopup: action.payload.openPopup
      }
    },
    setProjectEditSelectedIdAction(state, action) {
      return {
        ...state,
        editSelectedProjectId: action.payload.id
      }
    },
    createProjectAction(state) {
      return {
        ...state,
        loading: true
      }
    },
    setProjectCreateOrEditLoadingAction(state, action) {
      return {
        ...state,
        loading: action.payload.loading
      }
    },
    editProjectAction(state){
      return {
        ...state,
        loading: true
      }
    }
  }
})

export const {
  setProjectCreateOpenPopupAction,
  createProjectAction,
  setProjectCreateOrEditLoadingAction,
  editProjectAction,
  setProjectEditSelectedIdAction
} = projectCreateOrEditSlice.actions

export default projectCreateOrEditSlice.reducer