
const SortButton = ({ arrow, letterA, letterZ, isDate, dateVal }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div style={{ marginRight: '10px' }}>
        <span>{arrow}</span>
      </div>
      <div>
      {isDate ? <>
        <div>{letterA}</div>
        <div>{letterZ}</div> </> : <div>{dateVal} </div> }
      </div>
    </div>
  );
};

export default SortButton;
