import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { checkCutandFill, checkUnit } from "utils/layers/mapUtils";


const PointDetail = ({ item }) => {
    const progressCutFill = typeof item?.properties?.progress_cut_fill === 'number' && item?.properties?.progress_cut_fill
    

    return (
        <Grid container direction="column">
            <Grid item>
                <Box display="inline-flex" justifyContent="space-between" width="100%">
                    <Typography className="lblStyle">Name</Typography>
                    <Typography sx={{ color: item.color }} className="lblStyle">
                        {" "}
                        {item.name}
                    </Typography>
                </Box>
            </Grid>
            <Grid item>
                <Box display="inline-flex" justifyContent="space-between" width="100%">
                    <Typography className="lblStyle">Label</Typography>
                    <Typography className="lblStyle">{item.label}</Typography>
                </Box>
            </Grid>
            {item?.properties?.X && (
                <Grid item>
                    <Box
                        display="inline-flex"
                        justifyContent="space-between"
                        width="100%"
                    >
                        <Typography className="lblStyle">Northing</Typography>
                        <Typography className="lblStyle">
                            {item?.properties?.Y?.toFixed(2)} {item?.properties.unit} {checkUnit(item?.properties?.unit)}
                        </Typography>
                    </Box>
                </Grid>
            )}
            {item?.properties?.Y && (
                <Grid item>
                    <Box
                        display="inline-flex"
                        justifyContent="space-between"
                        width="100%"
                    >
                        <Typography className="lblStyle">Easting</Typography>
                        <Typography className="lblStyle">
                            {typeof item?.properties?.X === 'number' ? item?.properties?.X?.toFixed(2) : '-'} {item?.properties?.unit} {checkUnit(item?.properties?.unit)}
                        </Typography>
                    </Box>
                </Grid>
            )}
            {item?.properties?.elevation && (
                <Grid item>
                    <Box
                        display="inline-flex"
                        justifyContent="space-between"
                        width="100%"
                    >
                        <Typography className="lblStyle">Elevation</Typography>
                        <Typography className="lblStyle">
                            {typeof item?.properties?.elevation === 'number' ? `${item?.properties?.elevation.toFixed(2)} ${item?.properties?.unit} ${checkUnit(item?.properties?.unit)}` : '-'} 
                        </Typography>
                    </Box>
                </Grid>
            )}
            {item?.properties?.progress_cut_fill && item?.properties?.progress_cut_fill !== 0 ? (
                <Grid item>
                    <Box
                        display="inline-flex"
                        justifyContent="space-between"
                        width="100%"
                    >
                        <Typography className="lblStyle">Progress Cut-fill</Typography>
                        <Typography className="lblStyle">
                            {progressCutFill && progressCutFill ?
                                <>
                                    {typeof progressCutFill === 'number' ? checkCutandFill(progressCutFill, item?.properties?.unit, 'progress_cut_fill') : '-'}
                                </>
                                :
                                <>
                                    <span>-</span>
                                </>
                            }
                        </Typography>
                    </Box>
                </Grid>
            ): ''}
            {item?.properties?.design_elevation && (
                <Grid item>
                    <Box
                        display="inline-flex"
                        justifyContent="space-between"
                        width="100%"
                    >
                        <Typography className="lblStyle">Design Elevation</Typography>
                        <Typography className="lblStyle">
                            {typeof item?.properties?.design_elevation === 'number' ? `${ checkCutandFill(item?.properties?.design_elevation, item?.properties?.unit, 'design_elevation')}` : '-'}
                        </Typography>
                    </Box>
                </Grid>
            )}
            {item?.properties?.design_cut_fill && (
                <Grid item>
                    <Box
                        display="inline-flex"
                        justifyContent="space-between"
                        width="100%"
                    >
                        <Typography className="lblStyle">Design Cut-fill</Typography>
                        <Typography className="lblStyle">
                            {typeof item?.properties?.design_cut_fill === 'number' ? checkCutandFill(item?.properties?.design_cut_fill,item?.properties?.unit,'design_cut_fill') : '-'}
                        </Typography>
                    </Box>
                </Grid>
            )}
        </Grid>
    );
};

export default PointDetail;
