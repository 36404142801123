import { useLocation } from "react-router-dom";
import MeasurementWithMapContent from "./MeasurementWithMapContent";
import { useSelector } from "react-redux";
import { styled } from "@mui/material/styles";

import Box from "@mui/material/Box";

// const drawerWidth = 264;
const mobileDrawerWidth = 256;

const openedMixin = (theme, maindrawer) => ({
  // paddingLeft: maindrawer ? 305 : drawerWidth,
  [theme.breakpoints.down("sm")]: {
    marginLeft: maindrawer ? 290 : mobileDrawerWidth,
  },
  transition: theme.transitions.create("padding-left", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme, maindrawer) => ({
  transition: theme.transitions.create("padding-left", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  paddingLeft: maindrawer ? "66.047px" : `0px`,
});

const BoxStyled = styled(Box)(({ theme, open, maindrawer }) => ({
  [theme.breakpoints.down("sm")]: {
    ...(open && {
      ...openedMixin(theme, maindrawer),
    }),
    ...(!open && {
      ...closedMixin(theme, maindrawer),
    }),
  },
  ...(open && {
    ...openedMixin(theme, maindrawer),
  }),
  ...(!open && {
    ...closedMixin(theme, maindrawer),
  }),
  width: "100%",
  bottom: 0,
  position: "absolute",
  zIndex: 1,
}));

const WithMapContent = () => {
  const selectedItem = useSelector(
    (state) =>
      state.drawerState.menuItems.filter((item) => item.selected === true)[0]
  );
  const drawerExpand = useSelector((state) => state.drawerState.drawerExpand);
  const location = useLocation();
  return (
    <BoxStyled
      open={drawerExpand}
      maindrawer={!selectedItem.initialDrawer ? 1 : 0}
    >
      {location.pathname === "/measure" ? <MeasurementWithMapContent /> : null}
    </BoxStyled>
  );
};

export default WithMapContent;
