import {Avatar, ListItemAvatar, ListItemText, Menu, MenuItem, styled, Typography} from "@mui/material";
import {getIconComponentByTypeWithProps} from "../../utils/assets";
import {useDispatch, useSelector} from "react-redux";
import {setProjectMaterialForEdit} from "../../redux/slices/material/projectMaterial/edit";
import { useNavigate } from 'react-router-dom';
import DeleteDialog from "../Common/DeleteDialog";
import {
  deleteProjectMaterialAction,
  setDeleteProjectMaterialIdAction
} from "../../redux/slices/material/projectMaterial/delete";

const AvatarStyled = styled(Avatar)(({theme}) => ({
  backgroundColor: theme.palette.white + "!important",
  color: theme.palette.text.link + "!important",
}))

const MATERIAL_OPERATIONS = [
  {key: 'edit', title: 'Edit'},
  {key: 'delete', title: 'Delete'}
]

const MaterialActions = ({material, handleToggleMenuClose, anchorEl}) => {
  const openMenu = Boolean(anchorEl);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const deleteSelectedId = useSelector(state => state.projectMaterialDelete.selectedId)
  const loading = useSelector(state => state.projectMaterialDelete.loading)

  const handleMenuClick = (key) => {
    if (key === "edit") {
      dispatch(setProjectMaterialForEdit({projectMaterial: material}))
      handleToggleMenuClose()
      navigate("/material/edit")
    }
    if(key === "delete"){
      dispatch(setDeleteProjectMaterialIdAction({id: material.id}))
      handleToggleMenuClose()
    }
  }

  const handleDelete = () => {
      dispatch(deleteProjectMaterialAction({id: material.id}))
  }

  return (
    <>
      <Menu
        id="material-card-crud-dialog"
        aria-labelledby="material-card-crud-diaglog"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={openMenu}
        anchorEl={anchorEl}
        onClose={handleToggleMenuClose}
      >
        {MATERIAL_OPERATIONS.map((operation, index) => (
          <MenuItem
            sx={{
              minHeight: 32,
              height: 32,
            }}
            // button
            onClick={() => handleMenuClick(operation.key)}
            key={`material-operation-${operation.title}-${index}`}
          >
            <ListItemAvatar>
              <AvatarStyled>
                {getIconComponentByTypeWithProps(operation.key)({
                  fontSize: "small",
                })}
              </AvatarStyled>
            </ListItemAvatar>
            <ListItemText
              primary={
                <Typography variant="subtitle2">{operation.title}</Typography>
              }
            />
          </MenuItem>
        ))}
      </Menu>
      <DeleteDialog
        item={material}
        selectedId={deleteSelectedId}
        onClose={() => dispatch(setDeleteProjectMaterialIdAction({id: null}))}
        handleDelete={handleDelete}
        loading={loading}
      />
    </>

  )
}

export default MaterialActions