export const selectCombinedLayers = (state) => {
  const selectedFlightId = state.flightSelected.id;
  const userEmail = localStorage.getItem("userEmail");

  // if (state.rasterLayers?.combinedLayers) {
  //   return state.rasterLayers.combinedLayers;
  // }

  if (state.rasterLayers?.combinedLayers) {
    const combinedLayers = Object.values(state.rasterLayers.combinedLayers);
    const filteredLayers = combinedLayers.filter(layer => layer?.data?.flightId === selectedFlightId);
    if(filteredLayers.length) {

    return state.rasterLayers.combinedLayers;

    } // This will return the filtered layers that match the selectedFlightId
}

  const unsortedLayers = {};
  const layers = state.rasterLayers;
  const { panoImages } = state.seeList;

  if (userEmail === 'lce@hillplain.com') {
    unsortedLayers.wms_layer = {
      title: 'Base Layer',
      type: 'wmsLayer',
      layout: {
        visibility: window.map?.getLayer('parcel-fabric-wms-layer') ? 'visible' : 'none',
      },
    };
  }


  Object.keys(layers)
    .reverse()
    .forEach((layerKey) => {
      const layer = layers[layerKey];
      if (layer?.showInLayerSwitcher && layerKey.includes(state.flightSelected.id)) {
        unsortedLayers[layerKey] = { ...layer, type: 'rasterLayer' };
      }
    });


  state.lineworkList?.data?.forEach((lw, index) => {
    const baseId = 'linework';
    unsortedLayers[`linework_${index}`] = {
      ...lw,
      type: 'linework',
      layout: {
        lineVisibility: window.map?.getLayoutProperty(`${baseId}-line-${lw.uuid}`, 'visibility') || 'none',
        pointVisibility: window.map?.getLayoutProperty(`${baseId}-point-${lw.uuid}`, 'visibility') || 'none',
        polygonVisibility: window.map?.getLayoutProperty(`${baseId}-polygon-${lw.uuid}`, 'visibility') || 'none',
      },
    };
  });



 if (panoImages?.length) {
    unsortedLayers.panorama_layers = {
      title: 'Panorama Layers',
      type: 'panoramaGroup',
      layers: panoImages.map((pano) => ({
        ...pano,
        type: 'panorama',
        layout: { visibility: 'visible' },
      })),
    };
  }

  return Object.keys(unsortedLayers).map((layerKey) => ({
    name: layerKey,
    data: unsortedLayers[layerKey],
  }));
};
