import { styled } from "@mui/material/styles";
import {getIconComponentByType} from "../../utils/assets";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import ListItemIcon from "@mui/material/ListItemIcon";
import DatePicker from "components/Common/DatePicker";

import List from "@mui/material/List";
import {useDispatch, useSelector} from "react-redux";
import {useState} from "react";
import { resetFlightUpdateAction} from "../../redux/slices/flight/updateDate";
import {editFlightNameAction} from "redux/slices/flight/edit";


const ListItemIconStyled = styled(ListItemIcon)(() => ({
  minWidth: 0
}))

const StyledDatePicker = styled(DatePicker)`
  width: 75%;
`;

const FlightDate = ({flight}) => {
  const dispatch = useDispatch()
  const flightData = useSelector(state => state.flightDateUpdate)
  const [flightDate, setFlightDate] = useState(flightData.flown_date);

  const handleInputChange = e => {
    setFlightDate(e.target.value)

  }
  const handleSave = () => {
    dispatch(editFlightNameAction({data: {flown_date: flightDate}, id: flightData.id}))
    dispatch(resetFlightUpdateAction());

  }
  return (
    <List
    sx={{
      width: "100%"
    }}
    >
      <ListItem disableGutters disablePadding sx={{
        marginLeft: "16px"
      }}
      >
        <ListItemText
          disableTypography>
          <StyledDatePicker
            label="Flight Date"
            onChange={handleInputChange}
            value={flight.flown_date}
            required
            fullWidth
            name="flown_date"
            // error={errors.flown_date}
          />
        </ListItemText>
        <ListItemSecondaryAction>
                <ListItemIconStyled>
                  <IconButton onClick={handleSave}>{getIconComponentByType("save")}</IconButton>
                </ListItemIconStyled>
                <ListItemIconStyled>
                  <IconButton onClick={() => dispatch(resetFlightUpdateAction())}>{getIconComponentByType("close")}</IconButton>

                </ListItemIconStyled>
              </ListItemSecondaryAction>
      </ListItem>
    </List>
  )
}

export default FlightDate