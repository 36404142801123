import { createSlice } from "@reduxjs/toolkit";

const resetPasswordSlice = createSlice({
  name: "resetPassword",
  initialState: {
    loading: false,
    serverErrors: {},
    success: false,
  },
  reducers: {
    resetPasswordAction(state) {
      return {
        ...state,
        loading: true,
      };
    },
    resetPasswordConfirmAction(state) {
      return {
        ...state,
        loading: true,
      };
    },
    setResetPasswordServerErrorsAction(state, action) {
      return {
        ...state,
        loading: false,
        serverErrors: action.payload.errors,
      };
    },
    setResetPasswordSuccessAction() {
      return {
        loading: false,
        serverErrors: {},
        success: true,
      };
    },
    resetResetPasswordAction() {
      return {
        loading: false,
        serverErrors: {},
        success: false,
      };
    },
    setAuthorizeLinkRequest(state) {
      return {
        ...state,
        loading: true,
        success: false,
      };
    },
    sertAuthorizeLink() {
      return {
        loading: false,
        serverErrors: {},
        success: false,
      };
    },
  },
});

export const {
  resetPasswordAction,
  setResetPasswordServerErrorsAction,
  setResetPasswordSuccessAction,
  resetResetPasswordAction,
  resetPasswordConfirmAction,
  setAuthorizeLinkRequest,
  sertAuthorizeLink,
} = resetPasswordSlice.actions;

export default resetPasswordSlice.reducer;
