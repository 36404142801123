import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import Box from "@mui/material/Box";
import Map from "../../Map";
import CesiumMap from "../../CesiumMap";
import PanoramaComponent from "components/Panorama";
import ProjectMainContent from "./ProjectMainContent";
import WithMapContent from "./WithMapContent";
import Invoice from "../../Account/Invoice";
import { setMapViewAction, setPanoViewAction } from "../../../redux/slices/mapbox/mapSlice";
import { createProjectMarkerAction, getProjectListAction } from "../../../redux/slices/project/list";
import { hideAllLayersAction } from "../../../redux/slices/layers";
import { removeSelectedProjectAction } from "../../../redux/slices/project/selected";

const MainContent = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const mapView = useSelector((state) => state.mapState.mapView);
  const isCesiumView = useSelector((state) => state.mapState.is3D); 
  const projectMapView = useSelector((state) => state.projectList.mapView);
  const isPano = useSelector((state) => state.mapState.isPano);

  
  useEffect(() => {
    if (location.pathname === "/project") {
    if(isPano) dispatch(setPanoViewAction(false));

      dispatch(getProjectListAction());
      dispatch(removeSelectedProjectAction());
      dispatch(setMapViewAction({ mapView: projectMapView }));
    }
  }, [isPano, location.pathname, projectMapView, dispatch]);

  useEffect(() => {
    if (location.pathname === "/invoice") {
      dispatch(setMapViewAction({ mapView: false }));
    }
  }, [location.pathname, dispatch]);

  useEffect(() => {
    if (projectMapView) {
      window.map._markers.forEach((marker) => marker.remove());
      dispatch(createProjectMarkerAction({ history }));
      dispatch(hideAllLayersAction());
    }
  }, [projectMapView, dispatch, history]);

  const renderComponent = () => {
    if (location.pathname === "/project") {
      return !mapView && <ProjectMainContent />;
    }
    if (location.pathname === "/invoice") {
      return !mapView && <Invoice />;
    }

    dispatch(setMapViewAction({ mapView: true }));
  };
  return (
    <>

       <Box
        style={{ width: "100%" }}
        sx={{
          display: mapView ? "block" : "none",
        }}
      >
        {!isCesiumView ? <Map /> : <CesiumMap />} 
      </Box>
      {!mapView && isPano ? (
        <PanoramaComponent /> 
      ) : (
        !mapView && renderComponent()
      )}
      {mapView && <WithMapContent />} 
    </>
  );
};

export default MainContent;
