import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import {getIconComponentByType} from "../../utils/assets";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import MeasurementCreateOrEdit from "../../components/Measurement/Create";
import Box from "@mui/material/Box";
import {useContext, useEffect} from "react";
import {DrawToolContext} from "../../utils/layers/draw/DrawToolContext";
import { useNavigate } from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {setMeasurementGeoJsonForEditAction} from "../../redux/slices/measurement/edit";

const MeasurementEditPage = () => {
  const measurement = useSelector(state => state.measurementEdit.data)
  const drawTool = useContext(DrawToolContext)
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const handleBackClick = () => {
    navigate("/measure")
  }
  const handleEditClick = (features) => {
    dispatch(setMeasurementGeoJsonForEditAction({geojson: features[0]}))
  }
  useEffect(() => {
    drawTool.editDrawGeometry(measurement.geometry, handleEditClick)
    return () => {
      drawTool.finishGeometry()
    }
  }, []);
  
  return (
    <Box pt={2}>
      <Grid container alignItems="center">
        <Grid item xs={2}>
          <IconButton
            onClick={handleBackClick}
          >
            {getIconComponentByType("keyboardBack")}
          </IconButton>
        </Grid>
        <Grid item container xs={10} justifyContent="center">
          <Typography
            variant="h5">Edit {measurement.type === 0 ? "Point" : (measurement.type === 1 ? "PolyLine" : "Polygon")}</Typography>
        </Grid>
      </Grid>
      <Divider/>
      <MeasurementCreateOrEdit drawTool={drawTool} measurement={measurement}/>
    </Box>
  )
}
export default MeasurementEditPage