import React, { forwardRef, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SnackbarContent, useSnackbar } from "notistack";
import { styled, Tooltip } from "@mui/material";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import { getIconComponentByType, getIconComponentByTypeWithProps } from "../../utils/assets";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import { buildStyles, CircularProgressbarWithChildren } from "react-circular-progressbar";
import { deleteFlightUploadProgressEntryAction } from "../../redux/slices/flight/upload";

const SnackbarContentStyled = styled(SnackbarContent)(({ theme }) => ({
  [theme.breakpoints.up("sm")]: {
    minWidth: "426px !important",
  },
}));

const CardStyled = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  width: "100%",
}));

const CardActionStyled = styled(CardActions)(() => ({
  justifyContent: "space-between",
}));

const IconDivStyled = styled("div")(() => ({
  marginLeft: "auto",
}));

const ProgressCircleDivStyled = styled("div")(({ theme }) => ({
  float: "right",
  width: "30%",
  [theme.breakpoints.down("xs")]: {
    width: "15%",
  },
}));

const FlightUpload = forwardRef((props, ref) => {
  const flightUploads = useSelector((state) => state.flightUpload.data);
  const uploadedFiles = useSelector((state) => state.flightCreate?.rawFlight?.uploadedFiles || 0);
  const totalFiles = useSelector((state) => state.flightCreate?.rawFlight?.totalFiles || 0);
  const dispatch = useDispatch();
  const { closeSnackbar } = useSnackbar();
  const [expanded, setExpanded] = useState(false);
  const snackbarTittle = useSelector((state) => state.flightUpload.tittleMessage);

  const handleExpandClick = useCallback(() => {
    setExpanded((oldExpanded) => !oldExpanded);
  }, []);

  const handleDismiss = useCallback(() => {
    closeSnackbar(props.id);
  }, [props.id, closeSnackbar]);

  return (
    <SnackbarContentStyled ref={ref}>
      <CardStyled>
        <CardActionStyled>
          <Typography variant="body1">{snackbarTittle}</Typography>
          <IconDivStyled>
            <IconButton aria-label="Show more" onClick={handleExpandClick}>
              {expanded
                ? getIconComponentByType("expandMore")
                : getIconComponentByType("expandLess")}
            </IconButton>
          </IconDivStyled>
          <IconButton onClick={handleDismiss}>
            {getIconComponentByType("close")}
          </IconButton>
        </CardActionStyled>
        <Paper>
          {flightUploads.length > 0 &&
            flightUploads
              .filter((f) => f.uploadedStatus !== "success")
              .map((flightUpload) => (
                <React.Fragment key={flightUpload.flightName}>
                  <Divider />
                  <Card>
                    <CardActions>
                      <Grid container alignItems="center" alignContent="space-between">
                        <Grid item xs={6}>
                          <Typography gutterBottom>
                            {flightUpload.flightName}{" "}
                            {totalFiles && totalFiles > 0
                              ? ` - ${uploadedFiles} of ${totalFiles} files uploaded`
                              : ""}
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <ProgressCircleDivStyled>
                            <CircularProgressbarWithChildren
                              value={
                                flightUpload.uploadedPercentage < 100
                                  ? flightUpload.uploadedPercentage
                                  : 0
                              }
                              strokeWidth={8}
                              styles={buildStyles({
                                pathColor: "#000",
                                trailColor: "transparent",
                              })}
                            >
                              {flightUpload.uploadedPercentage < 100 ? (
                                <div style={{ fontSize: 12, marginTop: -10 }}>
                                  <strong>{flightUpload.uploadedPercentage}%</strong>
                                </div>
                              ) : flightUpload.uploadedPercentage === 100 &&
                                !flightUpload.uploadedStatus ? (
                                <div
                                  style={{
                                    width: "84%",
                                    marginLeft: 2,
                                    marginTop: 3,
                                  }}
                                >
                                  <Tooltip title="Processing">
                                    <CircularProgress />
                                  </Tooltip>
                                </div>
                              ) : flightUpload.uploadedStatus &&
                                flightUpload.uploadedStatus === "success" ? (
                                <Tooltip title="Upload Completed">
                                  {getIconComponentByTypeWithProps("checkCircle")({
                                    style: { width: "84%", color: "green" },
                                    fontSize: "large",
                                  })}
                                </Tooltip>
                              ) : (
                                <Tooltip title="Failed">
                                  {getIconComponentByTypeWithProps("error")({
                                    fontSize: "large",
                                    style: { color: "#DC143C" },
                                  })}
                                </Tooltip>
                              )}
                            </CircularProgressbarWithChildren>
                          </ProgressCircleDivStyled>
                        </Grid>
                        <Grid item xs={2}>
                          {flightUpload.uploadedStatus && (
                            <IconButton
                              size="medium"
                              onClick={() =>
                                dispatch(
                                  deleteFlightUploadProgressEntryAction({
                                    flightName: flightUpload.flightName,
                                  })
                                )
                              }
                            >
                              {getIconComponentByType("delete")}
                            </IconButton>
                          )}
                        </Grid>
                      </Grid>
                    </CardActions>
                  </Card>
                </React.Fragment>
              ))}
        </Paper>
      </CardStyled>
    </SnackbarContentStyled>
  );
});

FlightUpload.displayName = "FlightUpload";

export default FlightUpload;
