import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardMedia,
  IconButton,
  Typography,
 CardActionArea } from "@mui/material";

// import CTAvatarGroup from "../AvatarGroup";
import {
  getIconComponentByTypeWithProps,
} from "../../utils/assets";
import { useDispatch, useSelector } from "react-redux";
import ProjectActions from "./Actions";
import { selectProjectAction } from "../../redux/slices/project/selected";

import Loader from "../Common/Loader";
import { getRasterLayersAction } from "../../redux/slices/see/list";

// For Share functionality

// const AvatarStyled = styled(Avatar)(({theme}) => ({
//   backgroundColor: theme.palette.white + "!important",
//     color: theme.palette.text.link + "!important",
// }))

export default function ProjectCard({ project }) {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);

  // const handleViewClick = () => {
  //   setLoading(true);
  //   setTimeout(() => {
  //     dispatch(selectProjectAction({ project: project, history: history }));
  //   }, 2000);
  // };
  const handleViewClick = () => {
    setLoading(true);
    setTimeout(() => {
      dispatch(selectProjectAction({ project: project, history: history }));
      setLoading(false);

      // Check if the selected project has any flights
      const hasFlights = project.flights && project.flights.length > 0;

      if (!hasFlights) {
        // Remove markers of the previously selected flight
        if (window.map && window.map._markers) {
          window.map._markers.forEach((marker) => marker.remove());
        }
      } else {
        // If the selected project has flights, show the markers of the selected flight
        dispatch(getRasterLayersAction({ flightId: flightSelectedId }));
      }
    }, 2000);
  };
  const mapState = useSelector((state) => state.seeListState);
  const flightSelectedId = useSelector((state) => state.flightSelected.id);

  useEffect(() => {}, [mapState]);
  // const getHeaderTitle = () => {
  //   return (
  //     <Box>
  //       <Typography color="white" variant="body1">
  //         {project.name}
  //       </Typography>
  //       <Typography variant="caption">{`${project.description.slice(
  //         0,
  //         40
  //       )}...`}</Typography>
  //       {edit && getIconComponentByType("edit")}
  //     </Box>
  //   );
  // };

  const handleToggleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleToggleMenuClose = () => {
    setAnchorEl(null);
  };
  const [isLoading, setLoading] = useState(false);

  return (
    <Box>
      {isLoading ? <Loader /> : null}
      <Card
        elevation={2}
        sx={{
          width: 240,
          height: 182,
        }}
      >
        <CardActionArea onClick={handleViewClick}>
          <CardMedia
            image={project.thumbnail}
            alt={project.name}
            component="img"
            height="140"
            // width="240"

            // style={{
            //   //marginTop: -73,
            //   zIndex: 9999,
            //   height: "135px",
            //   width: "auto",
            // }}
          />
        </CardActionArea>
        {/* <CardHeader
          style={{ position: "absolute", top: "190px" }}
          action={
            <IconButton
              onClick={handleToggleMenuOpen}
              aria-label="update-card-details"
            >
              {getIconComponentByTypeWithProps("moreVert")({
                style: { color: "white" },
              })}
            </IconButton>
          }
          // title={getHeaderTitle()}
        /> */}

        <Box
          style={{
            flexDirection: "row",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            variant="h5"
            component="div"
            style={{ textTransform: "capitalize", marginLeft: 10 }}
          >
            {project.name}
          </Typography>
          <IconButton
            onClick={handleToggleMenuOpen}
            aria-label="update-card-details"
          >
            {getIconComponentByTypeWithProps("moreVert")({
              style: { color: "#FFC500" },
            })}
          </IconButton>
          {/* <Button
            label="View"
            style={{ marginLeft: "auto", padding: "6px 24px" }}
            onClick={handleViewClick}
          /> */}
        </Box>
        {/* <CardActions> */}
        {/*<CTAvatarGroup*/}
        {/*  list={AVATARS_GROUP_IMG_LIST}*/}
        {/*  handleAddProjectMemeber={handleAddProjectMemeber}*/}
        {/*/>*/}
        {/* <Button
            label="View"
            style={{ marginLeft: "auto", padding: "6px 24px" }}
            onClick={handleViewClick}
          />
        </CardActions> */}
        <ProjectActions
          project={project}
          handleToggleMenuClose={handleToggleMenuClose}
          anchorEl={anchorEl}
        />
      </Card>
    </Box>
  );
}
