import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "../Common/Button";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import FlightItem from "./FlightItem";
import FlightEdit from "./Edit";
import FlightDate from "./FlightDate";
import { isEmpty } from "lodash";
// import mapboxgl from 'mapbox-gl';
import { useState, useEffect } from 'react';
import { getFlightListAction } from "../../redux/slices/flight/list";
import { getRasterLayersAction } from "../../redux/slices/see/list";
import proj4 from 'proj4';
import { drawWMSLayers, moveMeasurementLayersToTop } from 'utils/layers/mapUtils';
import PrivateRequestBase from "../../redux/sagas/requests/rootRequest/PrivateRequestBase";


const FlightList = () => {
  const dispatch = useDispatch();
  const flights = useSelector((state) => state.flightList.data);
  const flightEdit = useSelector((state) => state.flightEdit);
  const flightDateUpdate = useSelector((state) =>{
    return state.flightDateUpdate;
  } );
  const flightSelectedId = useSelector((state) => state.flightSelected.id);
  const flightUuId = flights?.filter(fid => fid.id === flightSelectedId);
  const apiUrl = `${process.env.REACT_APP_BACKEND_URL}/events/flight/${flightUuId?.[0]?.uuid}`;
  const [isFlightedChecked, setIsFlightedChecked] = useState(false);
  // const loading = useSelector((state) => state.flightList.loading);
  const userEmail = localStorage.getItem("userEmail");
  // const flightData = useSelector((state) => state.seeList);


  useEffect(() => {
    drawWMSLayers();
    moveMeasurementLayersToTop('wms');
  },[flightSelectedId]);


 useEffect(() => {
  try {
    // const bbox = [-2336824.9573, -724284.1658000015, 2842801.139399998, 3827745.487400003];
    // const working1 = 'https://img.nj.gov/imagerywms/Natural2015?bbox={bbox-epsg-3857}&format=image/png&service=WMS&version=1.1.1&request=GetMap&srs=EPSG:3857&transparent=true&width=256&height=256&layers=Natural2015';
    // const wmsUrl = 'https://proxyinternet.nrcan.gc.ca/arcgis/services/CLSS-SATC/CLSS_Administrative_Boundaries/MapServer/WMSServer?service=wms&&bbox={bbox-epsg-3857}&request=getMap&srs=EPSG:3857&transparent=true&width=256&height=256&layers=National_Parks_and_National_Park_Reserves_of_Canada_Legislative_Boundaries';
    // const wmsUrl = 'https://proxyinternet.nrcan-rncan.gc.ca/arcgis/rest/services/MB_NC_NEW/WMB_Parcel/MapServer/WMSServer?LAYERS=4&TRANSPARENT=TRUE&FORMAT=image%2Fpng&TILED=true&SERVICE=WMS&VERSION=1.3.0&REQUEST=GetMap&STYLES=&SRS=EPSG:3857&BBOX=-2336824.9573,-724284.1658000015,2842801.139399998,3827745.487400003&WIDTH=256&HEIGHT=256';
   // const wmsUrl = 'https://maps.gov.bc.ca/arcgis/rest/services/whse/bcgw_pub_whse_cadastre/MapServer/?LAYERS=0&SERVICE=WMS&REQUEST=GetMap&VERSION=1.3.0&LAYERS=pub:WHSE_CADASTRE.PMBC_PARCEL_FABRIC_POLY_SVW&STYLES=&FORMAT=image/png&TRANSPARENT=true&CRS=EPSG:3857&BBOX=-139.1782824917356,47.60393449638617,-110.35337939457779,60.593907018763396&WIDTH=256&HEIGHT=256';
    //  const wmsUrl1 = 'https://proxyinternet.nrcan.gc.ca/arcgis/services/CLSS-SATC/CLSS_Administrative_Boundaries/MapServer/WMSServer?service=WMS&version=1.1.1&request=GetMap&layers=0&styles=&format=image/png&srs=EPSG:3857&bbox={bbox-epsg-3857}&width=256&height=256&transparent=true';
    const wmsUrl2 = 'https://proxyinternet.nrcan-rncan.gc.ca/arcgis/services/CLSS-SATC/CLSS_Administrative_Boundaries/MapServer/WMSServer?service=WMS&version=1.1.0&request=GetMap&layers=1&styles=&bbox={bbox-epsg-3857}&width=256&height=256&srs=EPSG:3857&format=image/png&transparent=true';
//  const www =    'https://proxyinternet.nrcan.gc.ca/arcgis/services/CLSS-SATC/CLSS_Administrative_Boundaries/MapServer/WMSServer?LAYERS=1&TRANSPARENT=TRUE&FORMAT=image%2Fpng&TILED=true&SERVICE=WMS&VERSION=1.1.1&REQUEST=GetCapabilities&srs=EPSG:3857&BBOX=-173.2908319999999947,36.5148119999999992,-13.0826449999999994,83.7274000000000029&transparent=true&width=256&height=256&layers=Aboriginal_Lands_of_Canada_Legislative_Boundaries';
    const parcelFabric = 'https://openmaps.gov.bc.ca/geo/pub/WHSE_CADASTRE.PMBC_PARCEL_FABRIC_POLY_SVW/ows?service=WMS&version=1.1.0&request=GetMap&layers=pub:WHSE_CADASTRE.PMBC_PARCEL_FABRIC_POLY_SVW&styles=&bbox={bbox-epsg-3857}&width=256&height=256&srs=EPSG:3857&format=image/png&transparent=true';
    // const storm_lines = 'https://services5.arcgis.com/YdvAKihFVMKJfTGm/arcgis/rest/services/Storm_lines_view/FeatureServer/23/query?where=1%3D1&outFields=*&outSR=3857&f=geojson';

  if (userEmail === 'lce@hillplain.com' && (!window?.map?.getSource('parcel-fabric-wms-source') ||
      !window?.map?.getSource('admin-boundaries-wms-source') || !window?.map?.getSource('storm_lines')
      )

    ) { //lce

      window.map.addSource('parcel-fabric-wms-source', {
        type: 'raster',
        tiles: [
          `${parcelFabric}`
        ],
        tileSize: 256,
      });

      window.map.addLayer({
        id: 'parcel-fabric-wms-layer',
        type: 'raster',
        source: 'parcel-fabric-wms-source',
        paint: { },
      });

    window.map.addSource('admin-boundaries-wms-source', {
        type: 'raster',
        tiles: [
          `${wmsUrl2}`
        ],
        tileSize: 256,
      });

      window.map.addLayer({
        id: 'admin-boundaries-wms-layer',
        type: 'raster',
        source: 'admin-boundaries-wms-source',
        paint: { },
      });

       const geojsonUrl = 'https://services5.arcgis.com/YdvAKihFVMKJfTGm/arcgis/rest/services/Storm_lines_view/FeatureServer/23/query?where=1%3D1&outFields=*&outSR=3857&f=geojson';

      fetch(geojsonUrl)
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(data => {

          data.features.forEach(feature => {
            feature.geometry.coordinates = feature.geometry.coordinates.map(coord => {
              return proj4('EPSG:3857', 'EPSG:4326', coord);
            });
          });
          window.map.addSource('storm_lines', {
            type: 'geojson',
            data: data
          });

          window.map.addLayer({
            id: 'storm_lines',
            type: 'line',
            source: 'storm_lines',
            layout: {},
            paint: {
              'line-color': '#149ECE',
              'line-width': 5
            }
          });
          // const coordinates = data.features.flatMap(f => f.geometry.coordinates);
          // const bounds = coordinates.reduce((bounds, coord) => bounds.extend(coord), new mapboxgl.LngLatBounds(coordinates[0], coordinates[0]));
          // window.map.fitBounds(bounds, { padding: 20 });
        })

        .catch(error => {
          console.error('There has been a problem with your fetch operation:', error);
        });


    
    }

  } catch (error) {
    console.error("Error adding WMS layer:", error);
  }


  },[]);


  useEffect(() => {
    let fetchDataInterval;

    const fetchData = async () => {
      try {
        if(flightUuId && !(flightUuId?.[0]?.processed)) {
        const response = await PrivateRequestBase("get", apiUrl);
        if (response?.data?.status) {
          dispatch(getFlightListAction());
          window.map &&
          window.map?.flyTo({
            center: response?.data?.project_data?.location.coordinates,
            zoom: 15,
            speed: 2,
          });
          dispatch(getRasterLayersAction({ flightId: response?.data?.flight_data?.id }));
          setIsFlightedChecked(true); 
          clearInterval(fetchDataInterval);
        }
      }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    if (flights?.length && !isFlightedChecked) {
      fetchDataInterval = setInterval(fetchData, 5000);
    }

    return () => clearInterval(fetchDataInterval);
  }, [flights, apiUrl, dispatch, isFlightedChecked]);

  return (
    <>
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        pt={2}
        pb={2}
      >
        <Typography variant="h4">Flights</Typography>
        <Box pt={2}>
          <Button
            label="Add New Flight"
            size="small"
            sx={{
              height: 37,
            }}
            component={NavLink}
            to="/flight/add"
          />
        </Box>
      </Box>
      <Divider />
      {flights.length > 0 ? (
        <Grid
          container
          direction="column"
          spacing={1}
          sx={{
            padding: "8px 8px 8px",
          }}
        >
         {flights?.map((flight, index) => {
           const isLast = index === 0;
          return (
            <Grid item xs key={flight.id}>
              {!isEmpty(flightDateUpdate) ? (flightDateUpdate?.id === flight?.id ? <FlightDate flight={flight}  /> : null) : null}
              {!isEmpty(flightEdit) ? (
                flightEdit.id === flight.id ? (
                  <>
                  <FlightEdit flight={flight} />
                  </>
                ) : (
                  <FlightItem flight={flight} isLast={isLast} />
                )
              ) : (
                <FlightItem flight={flight} isLast={isLast} />
              )}
            </Grid>
          );
        })}
        </Grid>

      ) : (
        <Typography align="center" mt={4}>
          No Flight Found
        </Typography>
      )}
    </>
  );
};

export default FlightList;
