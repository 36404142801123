import { call, put, select } from "redux-saga/effects";
import {
  createDesignRequest,
  deleteDesignFileRequest,
  getDesignListRequest,
} from "../requests/designRequest";
import {
  getDesignListAction,
  setDesignListDataAction,
} from "../../slices/design/list";
import { deleteDesignFileSuccessAction } from "../../slices/design/deleteAndSave";
import { resetDesignFileAction } from "redux/slices/design/create";

export function* createDesignHandler(action) {
  try {
    const projectId = yield select((state) => state.selectedProject.id);
    const formData = new FormData();
    formData.append("name", action.payload.name);
    formData.append("file", action.payload.file);
    formData.append("epsg_code", action.payload.epsg_code);
    formData.append("project", projectId);
    const response = yield call(createDesignRequest, formData);
    navigate("/design");
    yield put(resetDesignFileAction());
    yield put(setDesignListDataAction({ data: response.data }));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
}

export function* getDesignListHandler() {
  try {
    const selectedProjectId = yield select((state) => state.selectedProject.id);
    const response = yield call(getDesignListRequest, selectedProjectId);
    yield put(setDesignListDataAction({ data: response?.data?.data?.[0] }));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
}

// export function* saveDesignFileHandler(action) {
//   try {
//     let element = document.createElement("a");
//     element.href = action.payload.file;
//     element.setAttribute("download", "");
//     element.click();
//   } catch (error) {
//     console.log(error);
//   }
// }

export function* saveDesignFileHandler(action) {
  try {
    const file = action.payload.file;

    if (file) {
      let element = document.createElement("a");
      if (typeof file === "string") {
        element.href = file;
      } else if (file instanceof Blob) {
        const blobUrl = URL.createObjectURL(file);
        element.href = blobUrl;
      } else {
        throw new Error("Invalid file data in action payload.");
      }

      element.setAttribute("download", "");
      element.click();

      if (file instanceof Blob) {
        URL.revokeObjectURL(element.href);
      }
    } else {
      throw new Error("File data is missing in action payload.");
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
}

export function* deleteDesignFileHandler(action) {
  try {
    yield call(deleteDesignFileRequest, action.payload.id);
    yield put(getDesignListAction());
    yield put(deleteDesignFileSuccessAction());
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
}
