import SeeList from "../../components/See/List";

const SeePage = () => {
  return (
    <SeeList/>
    // <Layout
    //   sideNavComponent={
    //
    //   }
    // />

  )
}

export default SeePage