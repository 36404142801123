import Grid from "@mui/material/Grid";
import TextField from "../Common/TextField";
import Box from "@mui/material/Box";
import FileDropBox from "../Common/FileDropBox";
import SubmitResetButton from "../Common/SubmitResetButton";
import { useForm } from "../../hooks/useForm";
import PublicAPI from "../../utils/API/PublicAPI";
import proj4 from "proj4";
import { useDispatch, useSelector } from "react-redux";
import {
  createDesignAction,
  deleteDesignFileSuccessAction,
} from "../../redux/slices/design/create";
// import { useNavigate } from 'react-router-dom';
// import { useSnackbar } from "notistack";
import { useState } from "react";
import Loader from "../Common/Loader";

const initialValues = {
  name: "",
  epsg_code: "",
  file: null,
};

const DesignCreate = ({ design }) => {
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  // const { enqueueSnackbar } = useSnackbar();
  // const loading = useSelector((state) => state.designCreate.loading);
  // const loading = useSelector((state) => state.flightCreate.geotiff.loading);
  const createLoading = useSelector((state) => state.designCreate.loading);

  const [isLoading, setLoading] = useState(false);
  async function handleEPSGCode(e) {
    const response = await PublicAPI.request({
      method: "get",
      url: `https://epsg.io/${e.target.value}.proj4`,
    });
    if (response.data.includes("DOCTYPE html")) {
      setErrors((prevState) => ({
        ...prevState,
        epsg_code: "Invalid Epsg",
      }));
    } else {
      if (response.data) {
        setErrors((prevState) => ({
          ...prevState,
          epsg_code: "",
        }));
        proj4.defs(`EPSG:${e.target.value}`, response.data);
      } else {
        setErrors((prevState) => ({
          ...prevState,
          epsg_code: "Invalid Epsg",
        }));
      }
    }
  }

  const handleFile = (files) => {
    if (!values.epsg_code) {
      setErrors((prevState) => ({
        ...prevState,
        epsg_code: "Please Provide Epsg First",
      }));
    } else {
      setValues((prevState) => ({
        ...prevState,
        file: files[0],
      }));
    }
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("name" in fieldValues)
      temp.name = fieldValues.name ? "" : "This field is required.";
    if ("file" in fieldValues)
      temp.file = fieldValues.file ? "" : "This field is required.";
    if ("epsg_code" in fieldValues)
      temp.epsg_code = fieldValues.epsg_code ? "" : "This field is required.";

    // if ('elevation' in fieldValues)
    //   temp.elevation = fieldValues.elevation ? "" : "This field is required."
    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };
  const { values, errors, setErrors, handleInputChange, resetForm, setValues } =
    useForm(initialValues, validate, true);

  const handleSubmit = () => {
    if (validate()) {
      if (design) {
        dispatch(
          deleteDesignFileSuccessAction({ ...values, history: history })
        );
      } else {
        setLoading(true);
        // enqueueSnackbar("uploading", { persist: true, preventDuplicate: true });
        dispatch(createDesignAction({ ...values, history: history }));
        setLoading(false);
      }
    }
  };

  return (
    <Box pt={2} m={1}>
      {isLoading ? <Loader /> : null}
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <TextField
            label="Design Name"
            fullWidth
            required
            name="name"
            value={values.name}
            error={errors.name}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item>
          <TextField
            label="EPSG Code"
            fullWidth
            required
            name="epsg_code"
            value={values.epsg_code}
            error={errors.epsg_code}
            onChange={handleInputChange}
            onBlur={handleEPSGCode}
          />
        </Grid>
        <Grid item>
          <FileDropBox
            label="Design File"
            // acceptFileType="image/*"
            onFileDrop={handleFile}
            name="file"
            error={errors.file}
          />
        </Grid>
        <Grid item>
          {/* <Button
            type="submit"
            onClick={handleSubmit}
            name="Sign In"
            //loading={isLoading && isLoggedIn}
            variant="contained"
            className="mainBtn"
          >
            Submit
          </Button> */}
          <SubmitResetButton
            resetForm={resetForm}
            handleSubmit={handleSubmit}
            loading={createLoading}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default DesignCreate;
