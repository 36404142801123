import { createSlice } from "@reduxjs/toolkit";

const flightCreateSlice = createSlice({
  name: "flightCreateState",
  initialState: {
    geotiff: {
      loading: false,
    },
    rawFlight: {
      loading: false,
      totalFiles: 0,
      uploadedFiles: 0,
    },
  },
  reducers: {
    createGeoTiffFlightAction(state) {
      return {
        ...state,
        geotiff: { ...state.geotiff, loading: true },
      };
    },
    createRawFlightAction(state) {
      return {
        ...state,
        rawFlight: { ...state.rawFlight, loading: true },
      };
    },
    setRawFlightLoadingAction(state) {
      return {
        ...state,
        rawFlight: { ...state.rawFlight, loading: false },
      };
    },
    setGeoTiffLoadingAction(state) {
      return {
        ...state,
        geotiff: { loading: false },
      };
    },
    rawFilesDropAction(state) {
      return {
        ...state,
        rawFlight: { ...state.rawFlight, loading: true },
      };
    },
    rawFilesDropActionComplete(state) {
      return {
        ...state,
        rawFlight: { ...state.rawFlight, loading: false },
      };
    },
    setRawFileDataAction(state, action) {
      const prevDataArray = state.rawFlight[action.payload.flightUuid]?.data || [];
      return {
        ...state,
        rawFlight: {
          ...state.rawFlight,
          [action.payload.flightUuid]: {
            ...state.rawFlight[action.payload.flightUuid],
            data: [...prevDataArray, action.payload.data],
          },
        },
      };
    },
    updateRawFlightItemUploadStatusAction(state, action) {
      const flightData = state.rawFlight[action.payload.flightUuid]?.data || [];
      flightData.forEach((file, index) => {
        if (file.capturedTime === action.payload.capturedTime) {
          state.rawFlight[action.payload.flightUuid].data[index].uploaded = true;
        }
      });
    },
    updateRawFlightDataImageURLAction(state, action) {
      const flightData = state.rawFlight[action.payload.flightUuid]?.data || [];
      flightData.forEach((file, index) => {
        if (file.capturedTime === action.payload.capturedTime) {
          state.rawFlight[action.payload.flightUuid].data[index].image = action.payload.image;
        }
      });
    },
    removeRawFlightItemAction(state, action) {
      delete state.rawFlight[action.payload.flightUuid];
    },
    setTotalRawImageAction(state, action) {
      return {
        ...state,
        rawFlight: {
          ...state.rawFlight,
          [action.payload.flightUuid]: {
            ...state.rawFlight[action.payload.flightUuid],
            totalImages: action.payload.totalImages,
            uploading: action.payload.uploading,
          },
        },
      };
    },
    setRawFlightUploadingAction(state, action) {
      return {
        ...state,
        rawFlight: {
          ...state.rawFlight,
          [action.payload.flightUuid]: {
            ...state.rawFlight[action.payload.flightUuid],
            uploading: action.payload.status,
          },
        },
      };
    },
    increaseRawImageForUploadProcessAction(state, action) {
      return {
        ...state,
        rawFlight: {
          ...state.rawFlight,
          [action.payload.flightUuid]: {
            ...state.rawFlight[action.payload.flightUuid],
            uploadedImages: state.rawFlight[action.payload.flightUuid].uploadedImages + 1,
          },
        },
      };
    },
    decreaseRawImageForExifProcessAction(state, action) {
      return {
        ...state,
        rawFlight: {
          ...state.rawFlight,
          [action.payload.flightUuid]: {
            ...state.rawFlight[action.payload.flightUuid],
            totalImages: state.rawFlight[action.payload.flightUuid].totalImages - 1,
          },
        },
      };
    },
    setTotalFilesCountAction(state, action) {
      return {
        ...state,
        rawFlight: {
          ...state.rawFlight,
          totalFiles: action.payload.totalFiles,
        },
      };
    },
    clearUploadedFilesCountAction(state) {
    return {
        ...state,
        rawFlight: {
          ...state.rawFlight,
          totalFiles: 0,
        },
      };
    },
    clearTotalFilesCountAction(state) {
    return {
        ...state,
        rawFlight: {
          ...state.rawFlight,
          uploadedFiles: 0,
        },
      };
    },
    updateUploadedFilesCountAction(state, action) {
      return {
        ...state,
        rawFlight: {
          ...state.rawFlight,
          uploadedFiles: action.payload.uploadedFiles,
        },
      };
    },
    setFlightDuplicateNameAction: (state, action) => {
        state.flightDuplicateName = action.payload;
    },
    resetFlightDuplicateNameAction: (state) => {
        state.flightDuplicateName = '';
        state.rawFlight = { ...state.rawFlight, loading: false }
    },
    downloadSampleGCPFileAction() {},
    gcpFileDropAction() {},
    checkRawFlightToRemoveAction() {},
  },
});

export const {
  createGeoTiffFlightAction,
  createRawFlightAction,
  rawFilesDropAction,
  setRawFileDataAction,
  setTotalRawImageAction,
  decreaseRawImageForExifProcessAction,
  downloadSampleGCPFileAction,
  gcpFileDropAction,
  updateRawFlightDataImageURLAction,
  removeRawFlightItemAction,
  increaseRawImageForUploadProcessAction,
  setRawFlightUploadingAction,
  updateRawFlightItemUploadStatusAction,
  setRawFlightLoadingAction,
  setGeoTiffLoadingAction,
  checkRawFlightToRemoveAction,
  rawFilesDropActionComplete,
  setTotalFilesCountAction,
  updateUploadedFilesCountAction,
  clearTotalFilesCountAction,
  clearUploadedFilesCountAction,
  setFlightDuplicateNameAction,
  resetFlightDuplicateNameAction
} = flightCreateSlice.actions;

export default flightCreateSlice.reducer;
