import { createSlice } from "@reduxjs/toolkit";

const flightListSlice = createSlice({
  name: "flightListState",
  initialState: {
    loading: false,
    data: [],
  },
  reducers: {
    getFlightListAction(state) {
      return {
        ...state,
        loading: true,
      };
    },
    setFlightListDataAction(state, action) {
      return {
        ...state,
        loading: false,
        data: [...action.payload.data],
      };
    },
    clearFlightListAction(state) {
      return {
        ...state,
        data: [],
      };
    },
    createFlightWebSocketAction() {},
    updateFlightDataListAction(state, action) {
      state.data = [
        ...state.data.filter((item) => item.id !== action.payload.data.id),
        action.payload.data,
      ];
    },
    addWmsLayer(){},
    toggleWmsVisibility(state) {
      return {
        ...state,
        wmsVisible: !state.wmsVisible,
      };
    },
    setWmsOpacity(state, action) {
      return {
        ...state,
        wmsOpacity: action.payload,
      };
    }
  },
});

export const {
  getFlightListAction,
  setFlightListDataAction,
  createFlightWebSocketAction,
  updateFlightDataListAction,
  clearFlightListAction,
  addWmsLayer, 
  toggleWmsVisibility, 
  setWmsOpacity, 
} = flightListSlice.actions;

export default flightListSlice.reducer;