import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import DialogActions from "@mui/material/DialogActions";
import Button from "../Common/Button";
import Box from "@mui/material/Box";
import LoadingButton from "../Common/LoadingButton";
import {useDispatch, useSelector} from "react-redux";
import {deleteProjectAction} from "../../redux/slices/project/delete";

const DeleteDialog = ({ project, onClose }) => {
  const dispatch = useDispatch()
  const loading  = useSelector(state => state.projectDelete.loading)
  const selectedId  = useSelector(state => state.projectDelete.selectedId)

  const handleDelete = (e) => {
    e.stopPropagation()
    dispatch(deleteProjectAction({id: project.id}))
  }
  return(
    <Dialog
      open={project.id === selectedId }
      onClose={onClose}
       fullWidth
      maxWidth="xs"
      aria-labelledby="delete-project-dialog"
    >
      <DialogContent>
        <Typography align="center">Are you sure want to delete {project.name}?</Typography>
        <Typography align="center" variant="h4">All the data for {project.name} will be permanently deleted.</Typography>
      </DialogContent>
      <DialogActions>
        <Box
         display="flex"
         justifyContent="space-between"
        sx={{
          margin: "0 auto",
          width: "40%"
        }}
        >
          <Button
        label="Cancel"
        onClick={onClose}
        />
        <LoadingButton
        label="Delete"
        loading={loading}
        onClick={handleDelete}
        color="error"
        />

        </Box>


      </DialogActions>
    </Dialog>
  )
}

export default DeleteDialog