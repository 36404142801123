import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";

import { useDispatch, useSelector } from "react-redux";
import {
  handleMeasurementListDataAction,
  setMeasurementSortTypeAction,
} from "../../../redux/slices/measurement/list";
import SortButton from './SortButton';

const MenuItemStyled = styled(MenuItem)(() => ({
  padding: "4px 16px",
}));

const SortMeasurementMenu = ({ handleToggleMenuClose, anchorEl }) => {
  const openMenu = Boolean(anchorEl);
  const dispatch = useDispatch();
  const data = useSelector((state) => state.measurementList.data);

  const handleClick = (type) => {
    dispatch(setMeasurementSortTypeAction({ type: type }));
    dispatch(handleMeasurementListDataAction({ data: data }));
    handleToggleMenuClose();
  };

  return (
    <Menu
      id="measurement-sort-dialog"
      aria-labelledby="measurement-sort-dialog"
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={openMenu}
      anchorEl={anchorEl}
      onClose={handleToggleMenuClose}
    >
      <MenuItemStyled onClick={() => handleClick("nameAsc")}>
         <SortButton arrow="&darr;" letterA="A" letterZ="Z" isDate="false"/>
      </MenuItemStyled>
      <MenuItemStyled onClick={() => handleClick("nameDsc")}>
        <SortButton arrow="&uarr;" letterA="Z" letterZ="A" isDate="false"/>
      </MenuItemStyled>
      <MenuItemStyled onClick={() => handleClick("dateAsc")}>
        <SortButton arrow="&uarr;" dateVal="Old" />
      </MenuItemStyled>
      <MenuItemStyled onClick={() => handleClick("dateDsc")}>
        <SortButton arrow="&uarr;" dateVal="New"  />
      </MenuItemStyled>
    </Menu>
  );
};

export default SortMeasurementMenu;
