import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import basicImage from "../../../images/basicLayoutImage.png";
import {  Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

import BasicHeader from 'components/Basic/Header';

import Paper from "@mui/material/Paper";

const ImgStyled = styled("img")(() => ({
  height: "50vh",
  width: "100%",
  position: "fixed",
  top: 0,
}));

const HeroTextBox = styled(Box)(({ theme }) => ({
  position: "absolute",

  top: "14%",
  [theme.breakpoints.down("sm")]: {
    left: "0%",
    right: "0%",
  },
  [theme.breakpoints.up("sm")]: {
    left: "30%",
    right: "30%",
  },
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));

const MainContentBox = styled(Box)(({ theme }) => ({
  position: "absolute",

  top: "30%",
  [theme.breakpoints.down("sm")]: {
    left: "0%",
    right: "0%",
  },
  [theme.breakpoints.up("sm")]: {
    left: "30%",
    right: "30%",
  },
}));

const BrandContentBox = styled(Box)(({ theme }) => ({
  marginTop: 40,
  marginBottom: 20,
  // position: "fixed",
  // bottom: 0,
  [theme.breakpoints.down("sm")]: {
    left: "0%",
    right: "0%",
  },
  [theme.breakpoints.up("sm")]: {
    left: "30%",
    right: "30%",
  },
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));

const BasicLayout = ({
  children,
  headerButtonLabel,
  headerButtonLink,
  heroTextHeading,
  heroTextSubHeading,
}) => {
  return (
    <Box height="100vh" width="100%" position="absolute" mt={0}>
      <Box height="50%">
        <BasicHeader
          buttonLabel={headerButtonLabel}
          buttonLink={headerButtonLink}
        />
        <ImgStyled src={basicImage} alt="basicLayoutImage" />
      </Box>
      <HeroTextBox>
        <Box>
          <Typography
            variant="h1"
            color="white"
            sx={{
              fontStyle: "normal",
              fontWeight: "bold",
              fontSize: "40px",
              lineHeight: "65px",
              letterSpacing: "-1.32766px",
            }}
          >
            {heroTextHeading}
          </Typography>
        </Box>
        <Box>
          <Typography
            color="white"
            sx={{
              fontStyle: "normal",
              fontWeight: "normal",
              fontSize: "16px",
              lineHeight: "25px",
              textAlign: "center",
              letterSpacing: "-0.442553px",
            }}
          >
            {heroTextSubHeading}
          </Typography>
        </Box>
      </HeroTextBox>
      <MainContentBox>
        <Container maxWidth="xs">
          <Box component={Paper} elevation={2}>
            {children} 
            {/* <Outlet /> */}
          </Box>
        </Container>
        <BrandContentBox>
          <Typography>Copyright © 2024 Civil Tracker</Typography>
        </BrandContentBox>
      </MainContentBox>
    </Box>
  );

};

export default BasicLayout;
