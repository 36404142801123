import Menu from "@mui/material/Menu";
import { styled } from "@mui/material/styles";

import MenuItem from "@mui/material/MenuItem";
import {useDispatch, useSelector} from "react-redux";
import Grid from "@mui/material/Grid";
import {getIconComponentByTypeWithProps} from "../../../utils/assets";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Link , useNavigate } from "react-router-dom";

import {
  setProjectMaterialForSmartTrackingCreateAction,
  setSmartTrackingPolygonGeoJsonAction
} from "../../../redux/slices/smartTracking/create";
import {useContext} from "react";
import {DrawToolContext} from "../../../utils/layers/draw/DrawToolContext";
import {drawGeometryType} from "../../../utils/layers/draw/drawGeometryType";

const MenuItemStyled = styled(MenuItem)(()=> ({
  padding: "8px",
}))

const MenuStyled = styled(Menu)(() => ({
  "& .MuiMenu-list": {
    padding: 0
  }
}))

const StyledDiv = styled("div")(({color}) => ({
  height: 12,
  width: 12,
  backgroundColor: color,
  borderRadius: "50%"
}))

const SelectMaterialMenu = ({handleToggleMenuClose, anchorEl}) => {
  const projectMaterials = useSelector(state => state.projectMaterialList.data)
  const openMenu = Boolean(anchorEl)
  const dispatch = useDispatch()
  const drawTool = useContext(DrawToolContext)
  const navigate = useNavigate();

  const createPolygon = (feature) => {
    dispatch(setSmartTrackingPolygonGeoJsonAction({geojson: feature[0]}))
    navigate("/smart-tracking/add")
  }

  const handleClick = (projectMaterial) => {
    dispatch(setProjectMaterialForSmartTrackingCreateAction({id: projectMaterial.id}))
     drawTool.drawGeometry(drawGeometryType.polygon, createPolygon)
    handleToggleMenuClose()
  }

  return (
    <MenuStyled
      id="smart-tracking-select-material-dialog"
      aria-labelledby="smart-tracking-select-material-dialog"
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={openMenu}
      anchorEl={anchorEl}
      onClose={handleToggleMenuClose}
    >
      {projectMaterials.map(projectMaterial => (
        <MenuItemStyled key={projectMaterial.id} disableGutters divider onClick={() =>handleClick(projectMaterial)}>
          <Grid container alignItems="center">
            <Grid item xs={3}> <StyledDiv color={projectMaterial.color}/></Grid>
            <Grid item xs={6}><Typography>{projectMaterial.name}</Typography></Grid>
            <Grid item xs={3}>{getIconComponentByTypeWithProps("add")({
              fontSize: "small",
              color: "#fff"
            })}</Grid>
          </Grid>
        </MenuItemStyled>
      ))}
      <MenuItemStyled>
        <Button to="/material/add"
              component={Link}
              underline="none"
              variant="text"
              color="secondary"
        >Add New Material</Button>
      </MenuItemStyled>
    </MenuStyled>
  )
}

export default SelectMaterialMenu