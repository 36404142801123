import {createSlice} from "@reduxjs/toolkit";

const projectMaterialDeleteSlice = createSlice({
  name: "projectMaterialDelete",
  initialState: {
    loading: false,
    selectedId: null
  },
  reducers: {
    setDeleteProjectMaterialIdAction(state, action) {
      return {
        ...state,
        selectedId: action.payload.id
      }
    },
    deleteProjectMaterialAction(state) {
      return {
        ...state,
        loading: true
      }
    },
    deleteProjectMaterialSuccessAction() {
      return {
        loading: false,
        selectedId: null
      }
    }
  }
})

export const {setDeleteProjectMaterialIdAction, deleteProjectMaterialAction, deleteProjectMaterialSuccessAction} = projectMaterialDeleteSlice.actions

export default projectMaterialDeleteSlice.reducer