import { Navigate } from "react-router-dom";
const PrivateRoute = ({ element, authRequired = true }) => {
  const isAuthenticated = localStorage.getItem("isAuthenticated") === "true";

  if (!authRequired || isAuthenticated) {
    return element; 
    // return <>{element}</>;

  } else {
    return <Navigate to="/" replace />; 
  }
};

export default PrivateRoute;
