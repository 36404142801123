import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

import { getIconComponentByType } from "../../../utils/assets";
import Fab from "@mui/material/Fab";
import Collapse from "@mui/material/Collapse";
import Typography from "@mui/material/Typography";
import { useState, useEffect } from "react";
import ProfileGraph from "./Graph";
import Paper from "@mui/material/Paper";
import { useSelector , useDispatch } from "react-redux";
import {
  disableProfileAction
} from "redux/slices/measurement/profiler";


const openedMixin = (theme) => ({
  bottom: 240,
  transition: theme.transitions.create("bottom", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("bottom", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  bottom: 3,
});

const FabStyled = styled(Fab)(({ theme, expanded }) => ({
  position: "sticky",
  left: "40%",
  zIndex: 2,
  padding: 0,
  minHeight: "2px",
  width: "80px",
  height: "20px",
  borderRadius: "0px 4px 4px",
  ...(expanded && {
    ...openedMixin(theme),
  }),
  ...(!expanded && {
    ...closedMixin(theme),
  }),
}));

const Profiler = () => {
  const flights = useSelector((state) => state.flightList.data);
  const isProfileExpanded = useSelector((state) => state.measurementProfiler.isProfileExpanded);
  const dispatch = useDispatch();


  const measurementData = useSelector(
    (state) => state.measurementList.data
  );
  const distinctGeometryTypes = [
    ...new Set(measurementData?.map((item) => item?.geometry?.type)),
  ];
  const [expanded, setExpanded] = useState(false);


  useEffect(() => {
    if(isProfileExpanded) {
      setExpanded(isProfileExpanded);
    }
  },[isProfileExpanded]);

  const closeProfile = () => {
    dispatch(disableProfileAction())
    
  }

  return (
    <>
      {expanded && flights.length > 0 && distinctGeometryTypes.includes('polyLine') && (
        <FabStyled
          color="primary"
          aria-label="add"
          variant="extended"
          onClick={() => setExpanded(!expanded)}
          expanded={expanded ? 1 : 0}
        >
          <Box
            display="inline=flex"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
            px={1}
          >
            <Typography>Profile</Typography>
            {!expanded
              ? getIconComponentByType("expandLess")
              : getIconComponentByType("expandMore")}
          </Box>
        </FabStyled>
      )}
      {flights && flights.length > 0 && (
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Box component={Paper}>
            <ProfileGraph closeProfile={closeProfile} />
          </Box>
        </Collapse>
      )}
    </>
  );
};

export default Profiler;
