import {Avatar, ListItemAvatar, ListItemText, Menu, MenuItem, styled, Typography} from "@mui/material";
import {getIconComponentByTypeWithProps} from "../../utils/assets";
import {useDispatch, useSelector} from "react-redux";
import {setDeleteProjectSelectedIdAction} from "../../redux/slices/project/delete";
import DeleteDialog from "./DeleteDialog";
import {selectProjectAction} from "../../redux/slices/project/selected";
import ProjectCreateOrEdit from "./CreateOrEdit";
import {setProjectEditSelectedIdAction} from "../../redux/slices/project/createOrEdit";

const RECENT_PROJECT_OPERATIONS = [
  {key: 'eyeOpen', title: 'View'},
  {key: 'edit', title: 'Edit'},
  {key: 'delete', title: 'Delete'},
  // {key: 'groupAdd', title: 'Share'}
]

const AvatarStyled = styled(Avatar)(({theme}) => ({
  backgroundColor: theme.palette.white + "!important",
    color: theme.palette.text.link + "!important",
}))

const ProjectActions = ({project, handleToggleMenuClose, anchorEl}) => {

  const openPopup = useSelector(state => state.projectCreateOrEdit.editOpenPopup)

  const dispatch = useDispatch()
  const openMenu = Boolean(anchorEl);

  const OpenDeletePopup = useSelector(state => state.projectDelete.openPopup)

  const handleMenuClick = (e, key) => {
    e.stopPropagation()
    if (key === "delete") {
      dispatch(setDeleteProjectSelectedIdAction({id: project.id}))
    }
    else if (key === "eyeOpen") {
       dispatch(selectProjectAction({project: project, history: history}))
    }
    else if (key === "edit") {
      dispatch(setProjectEditSelectedIdAction({id: project.id}))
    }
    else {
    // eslint-disable-next-line no-console
      console.log("Invalid Action")
    }
    handleToggleMenuClose()
  };

  return (
    <>
    <Menu
          id="project-card-crud-dialog"
          aria-labelledby="project-card-crud-diaglog"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={openMenu}
          anchorEl={anchorEl}
          onClose={handleToggleMenuClose}
        >
          {RECENT_PROJECT_OPERATIONS.map((operation, index) => (
            <MenuItem
              sx={{
                minHeight: 32,
                height: 32,
              }}
              // button
              onClick={(e) => handleMenuClick(e, operation.key)}
              key={`project-operation-${operation.title}-${index}`}
            >
              <ListItemAvatar>
                <AvatarStyled>
                  {getIconComponentByTypeWithProps(operation.key)({
                    fontSize: "small",
                  })}
                </AvatarStyled>
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Typography variant="subtitle2">{operation.title}</Typography>
                }
              />
            </MenuItem>
          ))}
        </Menu>
      <DeleteDialog
      open={OpenDeletePopup}
      onClose={(e) => {
        e.stopPropagation()
        dispatch(setDeleteProjectSelectedIdAction({id: null}))
      }}
      project={project}
      />
      <ProjectCreateOrEdit
        open={openPopup}
        handleClose={(e) => {
         e.stopPropagation()
          dispatch(setProjectEditSelectedIdAction({id: null})
        )}}
        project={project}
        />
  </>
      )
}

export default ProjectActions