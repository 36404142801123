import Card from "@mui/material/Card";
import { getIconComponentByType } from "../../../utils/assets";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Collapse from "@mui/material/Collapse";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";

import CardHeader from "@mui/material/CardHeader";
import Avatar from "@mui/material/Avatar";
import { useState, useEffect } from "react";
import SmartTrackingPolygonDetail from "./PolygonDetail";
import SmartTrackingPolygonAction from "../Action";
import PrivateRequestBase from "redux/sagas/requests/rootRequest/PrivateRequestBase";
import {
  setSmartTrackingPolygonDataAction,
} from "redux/slices/smartTracking/list";
import { useDispatch } from "react-redux";


const CardHeaderStyled = styled(CardHeader)(({ theme, selected }) => ({
  "& .MuiCardHeader-avatar": {
    marginRight: "10px",
  },
  "& .MuiCardHeader-action": {
    height: "100%",
    marginTop: 0,
  },
  ...(selected && {
    backgroundColor: theme.palette.primary.main,
  }),
}));

const AvatarStyled = styled(Avatar)(() => ({
  height: "15px",
  width: "15px",
}));

const IconButtonStyled = styled(IconButton)(() => ({
  padding: "8px 5px",
}));

const SmartTrackingPolygonItem = ({ polygon }) => {
  const [expand, setExpand] = useState(false);
  const [processed, setProcessed] = useState(false);
  const [ newPolygon, setNewPolygon] = useState(polygon);
  const dispatch = useDispatch();

  const apiUrl = `${process.env.REACT_APP_BACKEND_URL}/events/polygon/${polygon?.uuid}`;

  useEffect(() => {
    let fetchDataInterval;

    const fetchData = async () => {
      try {
        if(!polygon.processed) {
        const response = await PrivateRequestBase("get", apiUrl);
        if (response?.data?.status) {
          setSmartTrackingPolygonDataAction({ data: response?.data?.data })
          setNewPolygon(response?.data?.data)
          setProcessed(true);
        }
      } 
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    if (polygon) {
      fetchDataInterval = setInterval(fetchData, 5000);
    }

    return () => clearInterval(fetchDataInterval);
  }, [ polygon, apiUrl, dispatch ]);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleExpand = () => {
    setExpand(!expand);
  };

  const handleToggleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleToggleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    polygon && (
      <>
        <Card raised>
          <CardHeaderStyled
            selected={expand ? 1 : 0}
            sx={{
              padding: "3px 5px 3px 16px",
            }}
            action={
              <Box display="inline-flex" alignItems="center">
                {!processed && !polygon.processed ? (
                  <CircularProgress size={22} color="secondary" />
                ) : (
                  <IconButtonStyled onClick={handleExpand}>
                    {!expand
                      ? getIconComponentByType("expandMore")
                      : getIconComponentByType("expandLess")}
                  </IconButtonStyled>
                )}
                <IconButtonStyled onClick={handleToggleMenuOpen}>
                  {getIconComponentByType("moreVert")}
                </IconButtonStyled>
              </Box>
            }
            avatar={
              <AvatarStyled sx={{ bgcolor: polygon.color }} aria-label="recipe">
                {" "}
              </AvatarStyled>
            }
            disableTypography
            title={
              <Typography variant="caption1" style={{ wordWrap: "break-word" }}>
                {polygon.name.length > 14
                  ? `${polygon.name.slice(0, 15)}...`
                  : polygon.name}
              </Typography>
            }
          />
          <Collapse in={expand} timeout="auto" unmountOnExit>
            <CardContent>
              <SmartTrackingPolygonDetail item={newPolygon} />
            </CardContent>
          </Collapse>
        </Card>
        <SmartTrackingPolygonAction
          polygon={polygon}
          handleToggleMenuClose={handleToggleMenuClose}
          anchorEl={anchorEl}
        />
      </>
    )
  );
};

export default SmartTrackingPolygonItem;
