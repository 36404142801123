import {createSlice} from "@reduxjs/toolkit";

const flightSelected = createSlice({
  name: "flightSelectedState",
  initialState: {},
  reducers: {
    selectFlightAction() {
    },
    setSelectedFlightAction(state, action) {
      return {
        ...action.payload.flight
      }
    },
    removeSelectedFlightAction() {
      return {}
    }
  }
})

export const {selectFlightAction, removeSelectedFlightAction, setSelectedFlightAction} = flightSelected.actions

export default flightSelected.reducer