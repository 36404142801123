import {createSlice} from "@reduxjs/toolkit";

const signupSlice = createSlice({
  name: "signup",
  initialState: {
    loading: false,
    serverErrors: {}
  },
  reducers: {
    signupAction(state) {
      return {
        ...state,
        loading: true
      }
    },
    setSignupServerSuccessAction() {
      return {
        loading: false,
        serverErrors: {}
      }
    },
    setSignupServerErrorsAction(state, action) {
      return {
        ...state,
        serverErrors: action.payload.errors,
        loading: false
      }
    },
    clearSignUpServerErrorsAction(state) {
      return {
        ...state,
        serverErrors: {},
      };
    }
  }
})

export const {signupAction, setSignupServerErrorsAction, setSignupServerSuccessAction, clearSignUpServerErrorsAction} = signupSlice.actions

export default signupSlice.reducer