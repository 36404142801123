// import axios from "axios";
import { gps, parse } from "exifr";

export async function createFileFromBlobURL(url){
  return await fetch(url).then(r => r.blob())
}

export async function getImageTagsHelper(image) {
  const { longitude, latitude } = await gps(image);
  return {
    tags: await parse(image, ["ImageDescription", "CreateDate"]),
    longitude,
    latitude,
  };
}
