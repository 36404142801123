/* eslint-disable unused-imports/no-unused-vars */
import { useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { Badge } from "@mui/material";
import FlightIcon from "@mui/icons-material/Flight";
import { PickersDay } from "@mui/x-date-pickers/PickersDay";
import TextField from "@mui/material/TextField";

const FlightDatePicker = ({
  flownOnDates = [],
  disableDates = false,
  showFlightIcon = false,
  onDateChange,
  value,
  ...props
}) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const isFlownDate = (date) =>
    flownOnDates.includes(dayjs(date).format("MM-DD-YYYY"));

  const CustomPickersDay = (props) => {
    const { day, ...other } = props;
    const isFlightDate = isFlownDate(day);

    return (
      <Badge
        key={day.toString()}
        overlap="circular"
        badgeContent={
          showFlightIcon && isFlightDate ? <FlightIcon color="primary" /> :  null
        }
      >
        <PickersDay {...other} day={day} disabled={disableDates && !isFlightDate} />
      </Badge>
    );
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        value={value}
        onChange={onDateChange}
      onClose={handleClose}
        shouldDisableDate={disableDates ? (date) => !isFlownDate(date) : undefined}
        slots={showFlightIcon ? { day: CustomPickersDay } : undefined}
        renderInput={(params) => <TextField {...params} />}
        {...props}
        // open={open}

      />
    </LocalizationProvider>
  );
};

export default FlightDatePicker;
