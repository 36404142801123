import { useTheme , AppBar as MuiAppBar } from "@mui/material";
import { styled } from "@mui/material/styles";

import Grid from "@mui/material/Grid";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import logo from "../../images/logo-long.svg";
import smallLogo from "../../images/icon.png";
import { useDispatch, useSelector } from "react-redux";
import { setOpenDrawerAction } from "../../redux/slices/dashboard/appbarSlice";
import AppBarContent from "./AppBarContent";
import Hidden from "@mui/material/Hidden";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { updateDrawerByCurrentLinkAction } from "../../redux/slices/dashboard/drawerSlice";

const ToolbarStyled = styled(Toolbar)(({ theme }) => ({
  height: "64px",
  [theme.breakpoints.down("sm")]: {
    height: "54px",
  },
}));

const MenuIconButtonStyled = styled(IconButton)(({ theme }) => ({
  display: "none",
  marginRight: theme.spacing(1),
  [theme.breakpoints.down("sm")]: {
    marginRight: theme.spacing(0.5),
    display: "inline-flex",
  },
}));

const BrandStyled = styled("div")(({ theme }) => ({
  lineHeight: 1,
  marginRight: theme.spacing(1),
  minWidth: "200px",
  [theme.breakpoints.down("sm")]: {
    minWidth: "200px", // Updated from "xs" to "sm"
  },
}));

const SmallIconImgStyled = styled("img")(() => ({
  height: "30px",
  width: "30px",
}));


export default function AppBar() {
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateDrawerByCurrentLinkAction({ link: location.pathname }));
  });

  const openDrawer = useSelector((state) => state.appbar.openDrawer);
  const handleDrawerOpen = () => {
    dispatch(setOpenDrawerAction({ openDrawer: true }));
  };

  const handleDrawerClose = () => {
    dispatch(setOpenDrawerAction({ openDrawer: false }));
  };

  const theme = useTheme();

  return (
    <MuiAppBar
      position="fixed"
      style={{ zIndex: theme.zIndex.drawer + 1 }}
      color="secondary"
    >
       <ToolbarStyled>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item container xs={2} alignItems="center">
            <Grid item xs={6}>
              {openDrawer ? (
                <MenuIconButtonStyled
                  edge="start"
                  onClick={handleDrawerClose}
                  color="inherit"
                  aria-label="open drawer"
                >
                  <CloseIcon />
                </MenuIconButtonStyled>
              ) : (
                <MenuIconButtonStyled
                  edge="start"
                  onClick={handleDrawerOpen}
                  color="inherit"
                  aria-label="open drawer"
                >
                  <MenuIcon />
                </MenuIconButtonStyled>
              )}
            </Grid>
            <Grid item xs={6} sm={12}>
              <BrandStyled>
                <Hidden smDown>
                  <img src={logo} alt="Civil Tracker" />
                </Hidden>
                <Hidden smUp>
                  <SmallIconImgStyled src={smallLogo} alt="Civil Tracker" />
                </Hidden>
              </BrandStyled>
            </Grid>
          </Grid>
          <Grid item xs={9}>
            <AppBarContent />
          </Grid>

          {/*<Grid item container xs={1} justify="flex-end">*/}
          {/*  <Grid item>*/}
          {/*    <IconButton aria-label="show 17 new notifications" color="primary">*/}
          {/*      <Badge badgeContent={17} color="secondary">*/}
          {/*        <NotificationsIcon/>*/}
          {/*      </Badge>*/}
          {/*    </IconButton>*/}
          {/*  </Grid>*/}
          {/*</Grid>*/}
        </Grid>
      </ToolbarStyled>
    </MuiAppBar>
  );
}
