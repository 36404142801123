import { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import { formatDate } from "../../utils/formattings";
import { project_list_enums } from "./enum";
import { getIconComponentByType } from "../../utils/assets";
import ProjectActions from "./Actions";
import Tooltip from "@mui/material/Tooltip";

const GetCellContents = ({ colKey, rowData }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleToggleMenuOpen = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleToggleMenuClose = () => {
    setAnchorEl(null);
  };
  const getByKey = (colKey) => {
    switch (colKey) {
      // case project_list_enums.PROJECT_IMAGE:
      //   return (
      //     <Box style={{ width: 52 }}>
      //       <Avatar
      //         sx={{ height: 29, width: 52 }}
      //         variant="rounded"
      //         src={rowData.thumbnail}
      //         alt="project-image"
      //       />
      //     </Box>
      //   );
      case project_list_enums.PROJECT_TITLE:
        return (
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Avatar
              sx={{ height: 40, width: 52 }}
              variant="rounded"
              src={rowData.thumbnail}
              alt="project-image"
            />
            <Typography
              style={{ marginLeft: 10, textTransform: "capitalize" }}
              variant="body1"
            >
              {rowData.name}
            </Typography>
          </Box>
        );

        case project_list_enums.PROJECT_EMAIL:
          return (
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Typography
                style={{ marginLeft: 10, textTransform: "capitalize" }}
                variant="body1"
              >
                {rowData.username}
              </Typography>
            </Box>
          );  
      case project_list_enums.PROJECT_DESCRIPTION:
        return (
          <Tooltip title={rowData.description}>
            <Typography variant="subtitle1">
              {`${rowData?.description?.slice(0, 70)}...`}
            </Typography>
          </Tooltip>
        );

      case project_list_enums.PROJECT_DATE:
        return formatDate(rowData.created_at, "yyyy-mm-dd");
      // case project_list_enums.PROJECT_TEAM: {
      //   return <CTAvatarGroup list={rowData.teams} />
      // }
      case project_list_enums.PROJECT_ACTION:
        return (
          <>
            <IconButton onClick={handleToggleMenuOpen}>
              {getIconComponentByType("moreVert")}
            </IconButton>
            <ProjectActions
              project={rowData}
              handleToggleMenuClose={handleToggleMenuClose}
              anchorEl={anchorEl}
            />
          </>
        );
      default:
        return;
    }
  };
  return <>{getByKey(colKey)}</>;
};

export default GetCellContents;
