import {createSlice} from "@reduxjs/toolkit";
// import {createProjectMaterialAction} from "./create";

const projectMaterialEdit = createSlice({
  name: "projectMaterialEdit",
  initialState: {
    loading: false,
    data: {}
  },
  reducers: {
    setProjectMaterialForEdit(state, action) {
      return {
        ...state,
        data: {...action.payload.projectMaterial}
      }
    },
    editProjectMaterialAction(state){
      return {
        ...state,
        loading: true,
      }
    },
    editProjectMaterialSuccessAction(){
      return {
        loading: false,
        data: {}
      }
    }
  }
})

export const {setProjectMaterialForEdit, editProjectMaterialAction, editProjectMaterialSuccessAction} = projectMaterialEdit.actions

export default projectMaterialEdit.reducer