import Card from "@mui/material/Card";
import { styled, Button } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import CardHeader from "@mui/material/CardHeader";
import { useRef, useEffect, useState } from 'react';
import { getIconComponentByType } from "../../../utils/assets";
import PointDetail from "./PointDetail";
import PolyLineDetail from "./PolyLineDetail";
import PolygonDetail from "./PolygonDetail";
import CircularProgress from "@mui/material/CircularProgress";
import MeasurementActions from "../Actions";
import { useDispatch, useSelector } from "react-redux";
import { measurementItemSelectAction, measureProfileSelectAction } from "../../../redux/slices/layers/geoJsonLayersSlice";
import { getMeasurementSelectedValue } from "../../../redux/sagas/helpers/measurementHelper";
import {
  removeMeasureProfileSelectAction,enableProfileAction
} from "../../../redux/slices/measurement/profiler";

const StyledBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  marginTop: '10px'
}));

const AvatarStyled = styled(Avatar)(() => ({
  height: "100%",
  width: "100%",
}));

const CardHeaderStyled = styled(CardHeader)(({ theme, selected }) => ({
  cursor: "pointer",
  "& .MuiCardHeader-avatar": {
    marginRight: "5px",
  },
  "& .MuiAvatar-root": {
    ...(selected && {
      backgroundColor: theme.palette.primary.main,
    }),
  },
  "& .MuiCardHeader-content": {
    display: "inline-block",
    width: "140px",
    whiteSpace: "nowrap",
    overflow: "hidden !important",
    textOverflow: "ellipsis",
  },
  padding: "5px",
  ...(selected && {
    backgroundColor: theme.palette.primary.main,
  }),
}));


const IconButtonStyled = styled(IconButton)(() => ({
  padding: "8px 5px",
}));

const MeasurementItem = ({ item, selectedFlightId }) => {
  const selected = useSelector((state) => {
    return getMeasurementSelectedValue(
      state.geoJsonLayers,
      item,
      selectedFlightId
    );
  });

  const cardRef = useRef(null);

  useEffect(() => {
    if (selected && cardRef.current) {
      cardRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
    }
  }, [selected]);

  const [anchorEl, setAnchorEl] = useState(null);

  const dispatch = useDispatch();

  const handleToggleMenuOpen = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleToggleMenuClose = () => {
    setAnchorEl(null);
  };

  const fetchProfile = () => {
    // dispatch(enableProfileAction())

    dispatch(
      measureProfileSelectAction({
        id: item.id,
        type: item.type,
        selected: true,
      })
    );

  };

  const handleExpand = () => {
    dispatch(removeMeasureProfileSelectAction())
    dispatch(enableProfileAction())


    dispatch(
      measurementItemSelectAction({
        id: item.id,
        type: item.type,
        selected: !selected,
      })
    );
  };
  const profileIcon = getIconComponentByType("profileIcon");

  return (
    item && (
      <>
        <Card raised style={{ marginBottom: 10 }} ref={cardRef}>
          {/* <Tooltip > */}
            <CardHeaderStyled
              onClick={handleExpand}
              selected={selected ? 1 : 0}
              disableTypography
              title={item?.name?.slice(0, 20)}
              avatar={
                <AvatarStyled sx={{ bgcolor: "#fff", color: "#000" }}>
                  {item.type === 0
                    ? getIconComponentByType("addLocation")
                    : item.type === 1
                    ? getIconComponentByType("polyLine")
                    : getIconComponentByType("polygon")}
                </AvatarStyled>
              }
              action={
                <Box display="inline-flex" alignItems="center">
                  {!item.processed && (
                    <CircularProgress size={22} color="secondary" />
                  )}
                  <IconButtonStyled onClick={handleExpand}>
                    {!selected
                      ? getIconComponentByType("expandMore")
                      : getIconComponentByType("expandLess")}
                  </IconButtonStyled>
                  <IconButtonStyled onClick={handleToggleMenuOpen}>
                    {getIconComponentByType("moreVert")}
                  </IconButtonStyled>
                </Box>
              }
            />
          {/* </Tooltip> */}
          <Collapse
            in={selected}
            timeout="auto"
            unmountOnExit
            style={{ width: "100%" }}
          >
            <CardContent style={{ padding: 10 }}>
              {item.type === 0 ? (
                <PointDetail item={item} />
              ) : item.type === 1 ? (
                <PolyLineDetail item={item} />
              ) : (
                <PolygonDetail item={item} />
              )}
              { 
              item.type === 1 && <StyledBox display="inline-flex" alignItems="center">
                <Button variant="outlined" startIcon={profileIcon} onClick={fetchProfile}>
                  View Profile
              </Button>
              </StyledBox>
            }
            </CardContent>
          </Collapse>
        </Card>
        <MeasurementActions
          measurement={item}
          handleToggleMenuClose={handleToggleMenuClose}
          anchorEl={anchorEl}
        />
      </>
    )
  );
};

export default MeasurementItem;
