import IconButton from "@mui/material/IconButton";
import {getIconComponentByType} from "../../../utils/assets";
import {setMeasurementGeoJsonAndType} from "../../../redux/slices/measurement/create";
import {drawGeometryType} from "../../../utils/layers/draw/drawGeometryType";
import {useDispatch} from "react-redux";
import {useContext} from "react";
import {DrawToolContext} from "../../../utils/layers/draw/DrawToolContext";
import { useNavigate } from 'react-router-dom';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const MeasurePolygonButton = ({buttonStyle={}, showLabel=false}) => {

  const dispatch = useDispatch()
  const drawTool = useContext(DrawToolContext)
  const navigate = useNavigate();

  const pointGeometryData = (feature) => {
    if (feature && feature.length > 0) {
      dispatch(setMeasurementGeoJsonAndType({geojson: feature[0], type: "Polygon"}))
      navigate("/measure/add")
    }
  }
  const handleClick = (callback) => {
    drawTool.drawGeometry(drawGeometryType.polygon, callback)
  }
  return (
    <Box display="inline-flex" alignItems="center" onClick={() => handleClick(pointGeometryData)}>
      <IconButton sx={buttonStyle}>
      {getIconComponentByType("polygon")}
    </IconButton>
      {showLabel && <Typography>Polygon</Typography>}
    </Box>

  )
}

export default MeasurePolygonButton