import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import MenuItems from "./MenuItems";

const InitialDrawer = () => {
  return (
    <>
      <Box>
        <Grid
          container
          direction="column"
          justifyContent="space-between"
          alignItems="center"
        >
          <MenuItems />
        </Grid>
      </Box>
      {/* <Box marginTop="auto"> */}
        {/* <PlanUpgradeBanner/> */}
        {/* <Account/> */}
      {/* </Box> */}
    </>
  );
};

export default InitialDrawer;
