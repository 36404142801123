const {createSlice} = require("@reduxjs/toolkit");
const flightDelete = createSlice({
  name: "flightDelete",
  initialState: {
    loading: false,
    selectedId: null
  },
  reducers:{
    deleteFlightAction(state){
      return {
        ...state,
        loading: true
      }
    },
    setDeleteFlightLoadingAction(state) {
      return {
        ...state,
        loading: false
      }
    },
    setDeleteFlightSelectedIdAction(state){
      return{
        ...state,
        selectedId: action.payload.id
      }
    }
  }
})

export const {deleteFlightAction, setDeleteFlightLoadingAction, setDeleteFlightSelectedIdAction} = flightDelete.actions
export default flightDelete.reducer