import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { googleLoginAction } from "../../redux/slices/basic/loginSlice";
import BasicLayout from "../../components/Basic/Layout";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import { signupAction } from "../../redux/slices/basic/signupSlice";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import Loader from "../../components/Common/Loader";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { yupResolver } from "@hookform/resolvers/yup";
import { validationSchemaSignup } from "../../components/Common/Validations";
import { useForm } from "react-hook-form";
import { clearSignUpServerErrorsAction } from "redux/slices/basic/signupSlice";



const GridItemStyled = styled(Grid)(() => ({
  width: "100%",
}));

const initialValues = {
  first_name: "",
  last_name: "",
  email: "",
  password1: "",
  password2: "",
};

const SignupPage = () => {
  const dispatch = useDispatch();

  const serveErrors = useSelector((state) => state.signup.serverErrors);
  const isLoggedIn = useSelector((state) => state.logout.isLoggedIn);
  const [isLoading, setLoading] = useState(false);


  // const validate = (fieldValues = values) => {
  //   let temp = { ...errors };
  //   if ("non_field_errors" in temp) temp.non_field_errors = "";
  //   if ("first_name" in fieldValues)
  //     temp.first_name = fieldValues.first_name ? "" : "First name is required.";
  //   if ("last_name" in fieldValues)
  //     temp.last_name = fieldValues.last_name ? "" : "Last name is required.";
  //   if ("email" in fieldValues)
  //     temp.email = fieldValues.email ? "" : "email is required.";
  //   if ("password1" in fieldValues)
  //     temp.password1 = fieldValues.password1 ? "" : "Password is required";
  //   if ("password2" in fieldValues)
  //     temp.password2 = fieldValues.password2
  //       ? ""
  //       : "Confirm password is required";
  //   setErrors({
  //     ...temp,
  //   });

  //   if (fieldValues === values)
  //     return Object.values(temp).every((x) => x === "");
  // };
  // const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
  //   useForm(initialValues, validate, true);

  // Google Login Success
  // const handleGoogleLoginSuccess = (response) => {
  //   const { accessToken, tokenId } = response;
  //   dispatch(
  //     googleLoginAction({ access_token: accessToken, id_token: tokenId })
  //   );
  // };

  useEffect(() => {
    dispatch(clearSignUpServerErrorsAction());
  }, []);

  //Google Login Failed
  // const handleGoogleLoginFailed = (error) => {
  //   console.log(error);
  //   //setErrors(error);
  // };

  const registerUser = (data) => {
    const payloadData = {
      first_name: data.first_name,
      last_name: data.last_name,
      email: data.email,
      password1: data.password1,
      password2: data.password2,
    };
    setLoading(true);
    setTimeout(() => {
      dispatch(signupAction({ ...payloadData }));
      setLoading(false);
    }, 2000);
  };

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchemaSignup),
  });
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <BasicLayout
      headerButtonLabel="Sign In"
      headerButtonLink="/"
      heroTextHeading="Create your free drone map now"
      heroTextSubHeading="100% free. No credit card needed"
    >
      {isLoading  ? <Loader /> : null}
      <Box display="flex" flexDirection="column" alignItems="center" py={2}>
        {/* <Box
          pt={2}
          width="90%"
          px={2}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <GoogleOAuthProvider clientId="YOUR_CLIENT_ID">
          <GoogleLogin
            onSuccess={handleSuccess}
            onError={handleError}
          />
        </GoogleOAuthProvider>
        </Box>
        <Box pt={2}>
          <Typography>OR</Typography>
        </Box> */}
      </Box>
      <form
        method="post"
        style={{ marginTop: 20 }}
        onSubmit={handleSubmit(registerUser)}
      >
        <Grid container direction="column" spacing={2} alignItems="center">
          <GridItemStyled container item>
            <Box px={2} display="flex">
              <GridItemStyled item>
                <FormControl sx={{ m: 1 }} variant="outlined">
                  <InputLabel htmlFor="first_name">First Name</InputLabel>
                  <OutlinedInput
                    id="first_name"
                    {...register("first_name")}
                    placeholder="First name"
                    label="first_name"
                    required
                  />
                  {errors.first_name && (
                    <span className="errorCls">
                      {errors.first_name.message}
                    </span>
                  )}
                </FormControl>
              </GridItemStyled>

              <GridItemStyled item>
                <FormControl sx={{ m: 1 }} variant="outlined">
                  <InputLabel htmlFor="last_name">Last Name</InputLabel>
                  <OutlinedInput
                    id="last_name"
                    {...register("last_name")}
                    placeholder="Last name"
                    label="last_name"
                    required
                  />
                  <span className="errorCls">{errors.last_name?.message}</span>
                </FormControl>
              </GridItemStyled>
            </Box>
          </GridItemStyled>
          <GridItemStyled item>
            <Box px={2}>
              <FormControl sx={{ m: 1, width: "95%" }} variant="outlined">
                <InputLabel htmlFor="email">Email</InputLabel>
                <OutlinedInput
                  id="email"
                  {...register("email")}
                  placeholder="Email"
                  label="Email"
                  required
                />
                <span className="errorCls">{errors.email?.message}</span>
              </FormControl>
            </Box>
          </GridItemStyled>
          {/* <GridItemStyled item>
            <Box px={2}>
              <TextField
                label="Email"
                fullWidth
                required
                name="email"
                value={values.email}
                error={errors.email}
                onChange={handleInputChange}
              />
            </Box>
          </GridItemStyled> */}
          <GridItemStyled item>
            <Box px={2}>
              <FormControl sx={{ m: 1, width: "95%" }} variant="outlined">
                <InputLabel htmlFor="password1">Password</InputLabel>
                <OutlinedInput
                  id="password1"
                  {...register("password1")}
                  placeholder="Password"
                  label="Password1"
                  required
                  type={showPassword ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <span className="errorCls">{errors.password1?.message}</span>
              </FormControl>
            </Box>
          </GridItemStyled>
          {/* <GridItemStyled item>
            <Box px={2}>
              <TextField
                label="Password"
                fullWidth
                required
                type="password"
                name="password1"
                value={values.password1}
                error={errors.password1}
                onChange={handleInputChange}
              />
            </Box>
          </GridItemStyled> */}
          <GridItemStyled item>
            <Box px={2}>
              <FormControl sx={{ m: 1, width: "95%" }} variant="outlined">
                <InputLabel htmlFor="password2">Confirm Password</InputLabel>
                <OutlinedInput
                  id="password2"
                  type={showPassword ? "text" : "password"}
                  {...register("password2")}
                  placeholder="Confirm password"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password2"
                  required
                />
                <span className="errorCls">{errors.password2?.message}</span>
              </FormControl>
            </Box>
          </GridItemStyled>
          {/* <GridItemStyled item>
            <Box px={2}>
              <TextField
                label="Confirm Password"
                fullWidth
                required
                type="password"
                name="password2"
                value={values.password2}
                error={errors.password2}
                onChange={handleInputChange}
              />
            </Box>
          </GridItemStyled> */}
          {/* {errors.non_field_errors && (
            <GridItemStyled item>
              <Box px={2}>
                <Typography color="error">{errors.non_field_errors}</Typography>
              </Box>
            </GridItemStyled>
          )} */}
          <GridItemStyled item>
            <Box px={2} pb={2}>
              <Button
                type="submit"
                //onClick={handleSubmit}
                name="Sign In"
                loading={isLoading && isLoggedIn}
                variant="contained"
                className="mainBtn"
              >
                Sign Up
              </Button>
              <Box px={2} pb={2} style={{color: 'red', marginTop: '3px' }}>
                {serveErrors?.email}
              </Box>
              {/* <LoadingButton
                onclick={handleSubmit}
                loading={loading}
                label="Sign Up"
                fullWidth
                sx={{
                  height: "30px",
                }}
              /> */}
            </Box>
            <Box px={2} pb={2} style={{ width: "100%", alignItems: "center" }}>
              <Typography>
                Already have an account?{" "}
                <Link to="/">
                  {" "}
                  <span
                    style={{
                      color: "black",
                      fontWeight: "bold",
                      textDecorationLine: "none",
                    }}
                  >
                    Sign In
                  </span>
                </Link>
              </Typography>
            </Box>
          </GridItemStyled>
        </Grid>
      </form>
    </BasicLayout>
  );
};

export default SignupPage;
