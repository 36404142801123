import { createSlice } from "@reduxjs/toolkit";

const projectListSlice = createSlice({
  name: "projectListState",
  initialState: {
    loading: false,
    mapView: false,
    data: []
  },
  reducers: {
    getProjectListAction(state){
      return {
        ...state,
        loading: true
      }
    },
    createProjectMarkerAction() {},
    setProjectListLoadingAction(state, action) {
      return{
        ...state,
        loading: action.payload.loading
      }
    },
    setProjectListDataAction(state, action){
      return {
        ...state,
        data : action.payload.data
      }
    },
    setProjectListMapViewAction(state, action){
      return {
        ...state,
        mapView: action.payload.mapView
      }
    }
  }
})

export const {
  getProjectListAction,
  setProjectListLoadingAction,
  setProjectListDataAction,
  setProjectListMapViewAction,
  createProjectMarkerAction
} = projectListSlice.actions

export default projectListSlice.reducer