import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectCombinedLayers } from 'redux/selectors/layersSelectors';
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Grid from "@mui/material/Grid";
import SeeItem from "./SeeItem";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import { updateLayersOrderAction } from "redux/slices/layers/rasterLayersSlice";

const SeeList = () => {
  const dispatch = useDispatch();
  const combinedLayers = useSelector(selectCombinedLayers); 
  const [wmsVisibility, setWmsVisibility] = useState(true); 

  // useEffect(() => {
  //   return () => {
  //     dispatch(resetCombinedLayers());
  //   };
  // }, [dispatch]);

const handleOnDragEnd = (result) => {
  if (!result.destination) return; 

  const sourceIndex = result.source.index;
  const destinationIndex = result.destination.index;
  if (sourceIndex === destinationIndex) return;

  // Ensure that layers can only be moved within their own type group
  // if (sourceLayer.data.type !== destinationLayer.data.type) {
  //   return; // Prevent the drop if it's across different types
  // }

  // Create a deep clone of the layers to be updated
  const updatedLayers = JSON.parse(JSON.stringify(combinedLayers));

  // Reorder layers within the allowed group
  const [movedLayer] = updatedLayers.splice(sourceIndex, 1); // Remove the layer from its original position
  updatedLayers.splice(destinationIndex, 0, movedLayer); // Insert the layer at the new position

  // Update the order property for layers
  updatedLayers.forEach((layer, index) => {
    if (layer?.data?.order !== undefined) {
      layer.data.order = index + 1;
    }
  });

  dispatch(updateLayersOrderAction(updatedLayers));
const updatedLayersCopy = [...updatedLayers].reverse();

  updatedLayersCopy.forEach((layer) => {
    const layerId = layer.name;

    if (layer.data.type === 'rasterLayer') {
      if (window.map.getLayer(layerId)) {
        window.map.moveLayer(layerId);
      }
    } else if (layer.data.type === 'wmsLayer') {
      const wmsLayerIds = ['parcel-fabric-wms-layer', 'admin-boundaries-wms-layer', 'storm_lines'];
      wmsLayerIds.forEach((wmsLayerId) => {
        if (window.map.getLayer(wmsLayerId)) {
          window.map.moveLayer(wmsLayerId);
        }
      });
    } else if (layer.data.type === 'linework') {
      const uuid = layer.data.uuid;
      if (window.map.getLayer(`linework-line-${uuid}`)) {
        window.map.moveLayer(`linework-line-${uuid}`);
      }
      if (window.map.getLayer(`linework-point-${uuid}`)) {
        window.map.moveLayer(`linework-point-${uuid}`);
      }
      if (window.map.getLayer(`linework-polygon-${uuid}`)) {
        window.map.moveLayer(`linework-polygon-${uuid}`);
      }
    }
  });
};



  const toggledWmsLayer = () => {
    setWmsVisibility(!wmsVisibility);
    window.map.setLayoutProperty('storm_lines', 'visibility', !wmsVisibility ? 'visible' : 'none');
    window.map.setLayoutProperty('admin-boundaries-wms-layer', 'visibility', !wmsVisibility ? 'visible' : 'none');
    window.map.setLayoutProperty('parcel-fabric-wms-layer', 'visibility', !wmsVisibility ? 'visible' : 'none');
  };

  return (
    <>
      <Box alignItems="center" display="flex" flexDirection="column" pt={2} pb={2}>
        <Typography variant="h4">See</Typography>
      </Box>
      <Divider />
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="combinedLayers">
          {(provided) => (
            <Grid
              container
              {...provided.droppableProps}
              ref={provided.innerRef}
              spacing={1}
              sx={{ padding: "8px 8px 8px" }}
            >
              {combinedLayers?.length > 0 && window.map ? (
                combinedLayers?.map((layer, index) => (
                  <Draggable key={layer.name} draggableId={layer.name} index={index}>
                    {(provided) => (
                      <Grid item container {...provided.draggableProps} ref={provided.innerRef}>
                        <Grid item xs>
                          <SeeItem
                            layerData={layer.data}
                            layerKey={layer.name}
                            provided={provided}
                            onToggleWmsLayer={toggledWmsLayer}
                          />
                        </Grid>
                      </Grid>
                    )}
                  </Draggable>
                ))
              ) : null}
              {provided.placeholder}
            </Grid>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
};

export default SeeList;