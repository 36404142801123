import { createSlice } from "@reduxjs/toolkit";

const designCreateSlice = createSlice({
  name: "designCreate",
  initialState: {
    loading: false,
  },
  reducers: {
    createDesignAction(state, action) {
      return {
        ...state,
        loading: true,
        ...action.payload.design,
      };
    },

    deleteDesignFileSuccessAction() {
      return {
        selectedId: null,
        loading: false,
      };
    },
    resetDesignFileAction() {
      return {
        loading: false
      }
    }
  },
});

export const { createDesignAction, deleteDesignFileSuccessAction, resetDesignFileAction } =
  designCreateSlice.actions;

export default designCreateSlice.reducer;
