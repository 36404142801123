import {getRasterLayerBase} from "../../../../utils/layers/rasterIntialLayers";
import {getMapBoxResourceId, setRasterLayerToMap, addPanoPointsToMap, moveMeasurementLayersToTop} from "../../../../utils/layers/mapUtils";
import {put, select, call} from "redux-saga/effects";
import {exchangeRasterLayerOrderAction, setRasterLayerData, updateLayersOrderAction} from "../../../slices/layers/rasterLayersSlice";

export function* createRasterLayerHandler(action) {
  try {
    const rasterLayers = action.payload.data?.flight_resources || action.payload.data;
    const { pano_images } = action.payload.data || [];
    if(pano_images)
      yield call(addPanoPointsToMap, pano_images, window.map);
    let layerArray = []
    if (rasterLayers.length > 0) {
      for (const rasterLayer of rasterLayers) {
        const rasterLayerData = getRasterLayerBase(rasterLayer.type)
        const layerId = rasterLayerData?.name && getMapBoxResourceId(rasterLayerData.name, rasterLayer.flight, true)
        // if(layerId)
        const projectLayer =  yield select(state => state.rasterLayers.hasOwnProperty(layerId) && state.rasterLayers[layerId])
        if (!!projectLayer) {
          layerArray.push(projectLayer)
        } else {
          if(rasterLayer.mapbox_tile_id) {
            rasterLayerData.mapboxTileIdKey = rasterLayer.mapbox_tile_id
            rasterLayerData.flightId = rasterLayer.flight
            layerArray.push(rasterLayerData)
          } else {
            if(rasterLayer?.type === 'ortho_cog') {
              rasterLayerData.cogUrl = rasterLayer.uri;
              rasterLayerData.flightId = rasterLayer.flight;
              layerArray.push(rasterLayerData);
            }
          
          }
          
        }
      }
      const sortedLayers = layerArray.sort((a, b) => a.order - b.order)
      for (const layer of sortedLayers) {
        window.map && setRasterLayerToMap(window.map, layer, layer.flightId, layer.layout.visibility, layer.cogUrl)
        const layerId = getMapBoxResourceId(layer.name, layer.flightId, true)
        yield put(setRasterLayerData({layerKey: layerId, layerData: layer}))
      }
      moveMeasurementLayersToTop('wms');
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error)
  }
}

export function* rearrangeLayersHandler(action) {
  try {
    const { sourceLayer, destinationLayer } = action.payload;

    const sourceType = sourceLayer.data.type;
    const destinationType = destinationLayer.data.type;

    const selectedFlightId = yield select(state => state.flightSelected.id);

    if (sourceType === 'rasterLayer' && destinationType === 'rasterLayer') {
      yield put(exchangeRasterLayerOrderAction({ sourceLayer: sourceLayer.name, destinationLayer: destinationLayer.name }));

      const rasterLayers = yield select(state => state.rasterLayersState);
      const sortedRasterLayers = Object.keys(rasterLayers)
        .filter(layerKey => layerKey.includes(selectedFlightId))
        .sort((a, b) => rasterLayers[a].order - rasterLayers[b].order);

      sortedRasterLayers.forEach((layer, index) => {
        const nextLayer = sortedRasterLayers[index + 1];
        window.map && window.map.moveLayer(layer, nextLayer || undefined);
      });
    } else {
      yield call(handleLayerReorder, { sourceLayer, destinationLayer });
    }
  } catch (error) {
    console.error('Error in rearrangeLayersHandler:', error);
  }
}

function* handleLayerReorder() {//{ sourceLayer, destinationLayer }
  const updatedLayers = yield select(state => state.combinedLayers);

  yield put(updateLayersOrderAction(updatedLayers));

  for (let i = 0; i < updatedLayers.length; i++) {
    const layer = updatedLayers[i];
    if (layer.data.type === 'rasterLayer' || layer.data.type === 'wmsLayer') {
      window.map.moveLayer(layer.name);
    } else if (layer.data.type === 'linework') {
      window.map.moveLayer(`linework-line-${layer.data.uuid}`);
      window.map.moveLayer(`linework-point-${layer.data.uuid}`);
      window.map.moveLayer(`linework-polygon-${layer.data.uuid}`);
    }
  }
}
