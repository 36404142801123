import CardHeader from "@mui/material/CardHeader";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import {getIconComponentByType} from "../../utils/assets";
import IconButton from "@mui/material/IconButton";
import Card from "@mui/material/Card";
import {useState} from "react";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import MaterialActions from "./Actions";

const CardHeaderStyled = styled(CardHeader)(() => ({
  "& .MuiCardHeader-avatar": {
    marginRight: "10px"
  },
  "& .MuiCardHeader-action": {
    height: "100%",
    marginTop: 0
  }
}))

const AvatarStyled = styled(Avatar)(() => ({
  height: "15px",
  width: "15px"
}))

const MaterialItem = ({projectMaterial, commonMaterial}) => {

  const commonMaterialObj = commonMaterial.filter(material => material.id === projectMaterial.material)[0]

  const [anchorEl, setAnchorEl] = useState(null);

  const handleToggleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleToggleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Card sx={{width: "100%"}} raised>
        <CardHeaderStyled
          sx={{
            padding: "3px 5px 3px 16px"
          }}
          action={
            <Box display="flex" justifyContent="center" alignContent="center">
              <IconButton onClick={handleToggleMenuOpen} aria-label="settings">
                {getIconComponentByType("moreVert")}
              </IconButton>
            </Box>
          }
          avatar={
            <AvatarStyled sx={{bgcolor: projectMaterial.color}} aria-label="recipe"> </AvatarStyled>
          }
          disableTypography
          title={<Typography variant="caption1" style={{wordWrap: "break-word"}}>{projectMaterial?.name.length > 14 ? `${projectMaterial?.name.slice(0, 15)}...`: projectMaterial.name}</Typography>}
          subheader={<Typography variant="subtitle1">{commonMaterialObj?.name}</Typography>}
        />
      </Card>
      <MaterialActions
      material={projectMaterial}
      anchorEl={anchorEl}
      handleToggleMenuClose={handleToggleMenuClose}
      />
    </>
  )
}

export default MaterialItem