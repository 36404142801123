import {Provider} from "react-redux";
import store, {persistor} from "./redux/store";
import {BrowserRouter} from "react-router-dom";
import {SnackbarProvider} from "notistack";
import FlightUpload from "./components/Flight/Upload";
import RouteList from "./routes/RouteList";
import {PersistGate} from "redux-persist/integration/react";

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
          <SnackbarProvider
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            content={(key) => (
              <FlightUpload id={key}/>
            )}
          >
            <BrowserRouter>
              <RouteList />
            </BrowserRouter>
          </SnackbarProvider>
      </PersistGate>
    </Provider>

  );
}

export default App;
