import { Navigate } from 'react-router-dom';

const PublicRoute = ({ element: Component }) => {
  const isAuthenticated = localStorage.getItem("isAuthenticated") === "true";

  const renderComponent = () => {
    if (window.location.pathname.includes("confirm-email")) {
      return Component;
    }

    return isAuthenticated ? <Navigate to="/project" /> : Component;
  };

  return renderComponent();
};

export default PublicRoute;
