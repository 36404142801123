import {Button as MuiButton} from "@mui/material";
import {getIconComponentByType} from '../../utils/assets'
import { styled } from "@mui/material/styles";

const PrimaryStyled = styled(MuiButton)(({theme}) => ({
  ...theme.typography.button,
  color: theme.palette.black + ' !important',
  background: theme.palette.primary.main,
  padding: theme.spacing(2),
  margin: 0,
  borderRadius: 8,
  '&:hover': {
    backgroundColor: theme.palette.primary.dark
  },
  height: 37
}))

const SecondaryStyled = styled(MuiButton)(({theme}) => ({
  ...theme.typography.button,
  color: theme.palette.white + ' !important',
  background: theme.palette.secondary.dark,
  padding: theme.spacing(2),
  margin: 0,
  borderRadius: 8,
  '&:hover': {
    backgroundColor: theme.palette.secondary.dark
  },
  height: 37
}))

const TYPE = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  START: 'start',
  END: 'end'
};

export default function Button({label, variant, type, iconPosition, iconType, ...props}) {
  const newProps = {...props};

  if (iconType) {
    const key = iconPosition === TYPE.END ? 'endIcon' : 'startIcon'
    newProps[key] = getIconComponentByType(iconType)
  }

  return (
    type === TYPE.PRIMARY ? <PrimaryStyled variant={variant} {...newProps}>
      {label}
    </PrimaryStyled> : <SecondaryStyled variant={variant} {...newProps}>
      {label}
    </SecondaryStyled>
  )
}

Button.defaultProps = {
  variant: 'contained',
  type: 'primary',
  iconPosition: 'start'
}